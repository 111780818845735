import React, { useState, useEffect, useContext } from "react";
import { Accordion } from "react-bootstrap";
import unCheck from "../../assets/icon/checkbox.svg";
import checked from "../../assets/icon/checkbox-selected.svg";
import { ClearAllContext } from "./ClearAllContext";

const AsideCollectionFilter = ({ onSelectedFilterChange }) => {
  const { clearAll, setClearAll } = useContext(ClearAllContext);

  const [selectCurrency, setSelectCurrency] = useState({
    allChains: true,
    flr: false,
    sgb: false,
    Coston: false,
    Coston2: false,
  });

  const handleCurrencyFilter = (tag) => {
    setSelectCurrency({
      allChains: tag === "allChains", // Select "All chains" if clicked
      flr: tag === "flr", // Select FLR if clicked
      sgb: tag === "sgb", // Select SGB if clicked
      Coston: tag === "Coston", // Select Coston if clicked
      Coston2: tag === "Coston2", // Select Coston2 if clicked
    });
  };

  const SearchFilter = () => {
    onSelectedFilterChange({
      selectCurrency,
    });
  };
  useEffect(() => {
    SearchFilter();
  }, []);
  useEffect(() => {
    SearchFilter();
  }, [selectCurrency]);


  useEffect(() => {
    console.log(clearAll, "Clear all");
    if (clearAll) {
      clearAllFilter();
      setClearAll(false); // Reset the clear flag after clearing filters
    }
  }, [clearAll]);

  const clearAllFilter = () => {

    setSelectCurrency({
      allChains: true,
      flr: false,
      sgb: false,
      coston: false,
      coston2: false,
    });
 
 
    SearchFilter();
  };

  return (
    <>
      <div className="collection-multi-filter">
        <div className="multi-filter-options">
          <Accordion>
            {/* Currency Filter */}
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <label className="h-64 no-text-transform text-black v-center cursor-pointer">
                  Currency
                </label>
              </Accordion.Header>
              <Accordion.Body>
                <div className="check-filter collection-status-filter">
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectCurrency.allChains ? "active" : ""
                    }`}
                    onClick={() => handleCurrencyFilter("allChains")}
                  >
                    <img
                      src={selectCurrency.allChains ? checked : unCheck}
                      alt="checkbox"
                    />
                    All chains
                  </p>
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectCurrency.flr ? "active" : ""
                    }`}
                    onClick={() => handleCurrencyFilter("flr")}
                  >
                    <img
                      src={selectCurrency.flr ? checked : unCheck}
                      alt="checkbox"
                    />
                    FLR
                  </p>
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectCurrency.sgb ? "active" : ""
                    }`}
                    onClick={() => handleCurrencyFilter("sgb")}
                  >
                    <img
                      src={selectCurrency.sgb ? checked : unCheck}
                      alt="checkbox"
                    />
                    SGB
                  </p>
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectCurrency.Coston ? "active" : ""
                    }`}
                    onClick={() => handleCurrencyFilter("Coston")}
                  >
                    <img
                      src={selectCurrency.Coston ? checked : unCheck}
                      alt="checkbox"
                    />
                    COSTON
                  </p>
                  <p
                    className={`body-large h-64 fw-normal v-center cursor-pointer ${
                      selectCurrency.Coston2 ? "active" : ""
                    }`}
                    onClick={() => handleCurrencyFilter("Coston2")}
                  >
                    <img
                      src={selectCurrency.Coston2 ? checked : unCheck}
                      alt="checkbox"
                    />
                    COSTON2
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default AsideCollectionFilter;
