const environment = process.env.REACT_APP_VERCEL_ENV;

console.log(process.env.REACT_APP_PINATA_GATEWAY_STAGING,"tewt");

const config = {
  marketplace: {
    coston: environment === 'production'
      ? process.env.REACT_APP_PROD_MARKETPLACE_CONTRACTADDRESS
      : process.env.REACT_APP_LOCAL_MARKETPLACE_CONTRACTADDRESS,
    coston2: environment === 'production'
      ? process.env.REACT_APP_PROD_MARKETPLACE_CONTRACTADDRESS2
      : process.env.REACT_APP_LOCAL_MARKETPLACE_CONTRACTADDRESS2,
  },
  erc20: {
    coston: environment === 'production'
      ? process.env.REACT_APP_PROD_ERC20_TOKEN_CONTRACTADDRESS
      : process.env.REACT_APP_LOCAL_ERC20_TOKEN_CONTRACTADDRESS,
    coston2: environment === 'production'
      ? process.env.REACT_APP_PROD_ERC20_TOKEN_CONTRACTADDRESS2
      : process.env.REACT_APP_LOCAL_ERC20_TOKEN_CONTRACTADDRESS2,
  },
  pinata: {
    apiKey: environment === 'production'
      ? process.env.REACT_APP_PROD_PINATA_API_KEY
      : process.env.REACT_APP_LOCAL_PINATA_API_KEY,
    apiSecret: environment === 'production'
      ? process.env.REACT_APP_PROD_PINATA_API_SECRET
      : process.env.REACT_APP_LOCAL_PINATA_API_SECRET,
    gateway: environment === 'production'
      ? process.env.REACT_APP_PINATA_GATEWAY_PRODUCTION
      : process.env.REACT_APP_PINATA_GATEWAY_STAGING,
  },
  emailjs: {
    publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
    serviceId: process.env.REACT_APP_EMAILJS_SERVICE_ID,
    templateId: process.env.REACT_APP_EMAILJS_TEMPLATE_ID
  }
};

export default config;


