import React, { useState, useEffect } from "react";
import Input from "./inputs";
import thumbnail from "../assets/icon/artwork.svg";
import bin from "../assets/icon/bin.svg";

import { toast } from "react-toastify";

const ImageUploadCollection = ({ onChange, value, setImage, locate }) => {
  const [selectedMedia, setSelectedMedia] = useState();
  const [limtExceeded, setLimitExceeded] = useState(false);


  // check size of image and set it to selectedMedia

  useEffect(() => {
    if (value?.size) {

      let maxSizeMB;
      if(locate=="Collection"){
        maxSizeMB = 1
      }else{
        maxSizeMB = 8
      }

      const maxSizeBytes = maxSizeMB * 1024 * 1024; // Convert MB to bytes

      if (value?.size > maxSizeBytes) {
        setSelectedMedia(null);
        setImage(null);
        toast.error(`Image size exceeded (Max: ${maxSizeMB}MB)`);
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setSelectedMedia(reader?.result);
      };
      setImage(value);
      reader?.readAsDataURL(value);
    } else {
      setSelectedMedia(null);
    }
    {if (value !== null && value !== undefined && typeof value === 'string'){
        if(value.startsWith("http")){
            setSelectedMedia(value);
        }
    }}
    
  }, [value]);

  // handle image change
  const handleMediaChange = (event) => {
    const media = event.target.files[0];
    if (media && media.type.startsWith("image/")) {
      onChange(event);
    } else {
    //   toast.error("Only images are allowed!");
    }
  };

  // handle delete media

  const handleDeleteMedia = () => {
    setSelectedMedia(null);
    setImage(null);
  };

  return (
    <div>
      <div className="file-thumbnails">
        <div className="mt-3 position-relative">
          <label className="file-thumbnail pointer position-relative">
            <img
              src={thumbnail}
              alt="thumbnail"
              className={`img-48 bin ${selectedMedia ? "d-none" : ""}`}
            />
            <Input
              type="file"
              className="d-none"
              accept="image/*"
              onChange={(e) => handleMediaChange(e)}
            />
            {selectedMedia && (
              <img
                src={selectedMedia}
                className="img-100 opacity-100 uploaded-img"
              />
            )}
          </label>
        </div>
        {selectedMedia && (
          <img
            src={bin}
            alt="bin"
            className="img-142 bin bg-white rounded-circle"
            onClick={handleDeleteMedia}
          />
        )}
      </div>
    
    </div>
  );
};

export default ImageUploadCollection;
