import React, { useState, useEffect } from "react";
import "../style/main.scss";
import { Icon } from "@iconify/react";
import img65 from "../assets/icon/spiked-circle/black/65px.svg";
import arrowAngle from "../assets/icon/arrow-angle-right.svg";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { app, getUserData } from "../firebase/firebase";
import Whitelist from "./admin/whitelist";


const Application = () => {
  const { address } = useAccount();
  console.log(address, "address");
  const [alreadyArtist, setAlreadyArtist] = useState(false);
  const [inCompleteProfile, setInCompleteProfile] = useState(false);
  const [whiteList, setWhiteList] = useState(false);

  const handleApplyClick = () => {
    if (!address) {
      toast.info("Please sign in to apply.");
    }
    if (address && alreadyArtist) {

      toast.info("Already registered as artist");
    }
    if (inCompleteProfile) {
      toast.info("Please complete your profile to register");
    }
  };
  
  useEffect(() => {
    const fetchArtistDetails = async () => {
      console.log(address, "address");

      let res = await getUserData(address);
      console.log(res, "rest")
        ;
      if (res?.isWhiteListed) {
        console.log(res, "rest");
        setAlreadyArtist(true);
      }
      if (res?.userName === " " || res === null) {

        setInCompleteProfile(true);
      }




    }
    if (address) {
      fetchArtistDetails();
    }

  }, [address])

  return (
    <>
      {/* In this section Artist apply to showcase work */}

      <section className="application">
        <div className="application-content">
          {/* <p>we hand-select the world's leading artist's</p> */}
          <h1 className="italic">artist</h1>
          <h2>application</h2>
          <p>
            <img src={img65} alt="circle-65" className="img-65" />
            apply to showcase your work
          </p>
        </div>

        {(!address || alreadyArtist || inCompleteProfile) ? (
          <a onClick={handleApplyClick}>
            {/* Start of link content */}
            <div className="application-footer">
              {/* Title */}
              <h4>Apply</h4>
              {/* View more arrow */}
              <div className="view-more">
                <img src={arrowAngle} alt="arrow" />
              </div>
            </div>
          </a>) :
          (
            <a href="https://docs.google.com/forms/d/1qLYHb4x9zU26HdjxH415GTvrDvpP3HXrEyerQm4GlaI/edit" target="black" onClick={handleApplyClick}>
              {/* Start of link content */}
              <div className="application-footer">
                {/* Title */}
                <h4>Apply</h4>
                {/* View more arrow */}
                <div className="view-more">
                  <img src={arrowAngle} alt="arrow" />
                </div>
              </div>
            </a>
          )
        }
      </section>
    </>
  );
};

export default Application;
