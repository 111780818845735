import React, { useState, useEffect, useContext } from "react";

import Button from "../button";
import "../../style/main.scss";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../firebase/firebase";
import { handleFollow } from "../../firebase/firebase";
import { checkIfFollowed } from "../../firebase/firebase";
import thumb from "../../assets/icon/profile-picture.svg";
import marketplaceContractABI from "../../abis/Marketplace/abi.json";
import mintContractABI from "../../abis/SafeMint/abi.json";
import { useNetwork } from "wagmi";
import { useAccount } from "wagmi";
import Web3 from "web3";
import { NFTContext } from "../context/appContext";

import {
  unfollowArtist,
  getFollowersData,
  getArtCreatedByArtistLength,
  getUserDataByUserName,
  getAllUsers,
  getCollectionByAddress,
  getFollowersByAddress,
  handleNotifications
} from "../../firebase/firebase";
import { add, create } from "lodash";
import config from "../../config";
const ArtistPopUp = ({ userProfile, verified, left, top, artistName }) => {
  console.log(userProfile, "userProfile");

  console.log(artistName);
  const [follow, setFollow] = useState(true);
  const { address } = useAccount();
  const [totalFollowers, setTotalFollowers] = useState(0);

  const [userAddress, setUserAddress] = useState(null);
  const [userData, setUserData] = useState([]);
  const [isWhiteListed, setIsWhiteListed] = useState(false);
  const [collected, setCollected] = useState(false);
  const { createdArtworks } = useContext(NFTContext)
  const [user, setUser] = useState([]);
  const [collections, setCollection] = useState([]);
  const [created, setCreated] = useState(0);
  const [name, setName] = useState(null);
  const [getAllUsersData, setGetAllUsersData] = useState(null);
  // network name
  const { chain } = useNetwork();

  const { marketplace, erc20 } = config;
  console.log(marketplace);
  console.log(erc20);



  const Marketplace_coston_contractAddress =
    marketplace.coston;
  const Marketplace_coston2_contractAddress =
    marketplace.coston2;
  const contractABI = marketplaceContractABI;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;
  useEffect(() => {
    const getData = async () => {
      let res = await getUserData(address);
      console.log(res, "response from user");
      setUser(res?.userName);
      let artist;
      if (artistName?.startsWith('@')) {
        artist = artistName.slice(1);
      }
      else {
        artist = artistName;
      }
      if (artist === "You" || artist === res?.userName) {
        setName(res?.userName);
      }
      else {
        setName(artist);
      }
    };
    getData();
  }, [address]);

  useEffect(() => {
    console.log(name, "name");

  }, [name])
  useEffect(() => {
    console.log(name);
    let artist;
    const fetchData = async () => {
      try {
        const res = await getUserDataByUserName(name);
        console.log(res, "response of get user data by username");

        console.log(res?.documentId);
        if (res) {

          setUserAddress(res?.documentId);
          setIsWhiteListed(res?.s?.isWhiteListed);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchData();
  }, [name, artistName]);

  useEffect(() => {
    console.log(name);
    console.log(user);

    if (name == user) {
      const getCollection = async () => {
        let res = await getCollectionByAddress(address);
        console.log(res, "collection data");
        setCollection(res);
      }
      getCollection();
    }

    // 0x69c5bbD5C7E5453B84167443beaF088CDD18e25f
    else {
      console.log(userAddress, "userAddress");
      const getCollection = async () => {
        let res = await getCollectionByAddress(userAddress);
        console.log(res, "collection data");
        setCollection(res);
      }
      getCollection();
    }




  }, [address, userAddress, name, userAddress])



  useEffect(() => {
    console.log(collections, "collections");

  }, [collections])
  const getCreatedData = async () => {
    let addr;
    if (name == user) {
      addr = address;
    } else {
      addr = userAddress;
    }
    console.log(name);
    console.log(userAddress);
    console.log(collections);

    if (collections?.length > 0) {
      let total = 0;
      let web3_1;
      console.log(collections?.length,"collections length");

      for (let i = 0; i < collections.length; i++) {
        console.log( collections[i],"collection address");

        if(collections[i]?.selectedNetwork === "Coston"){
          web3_1 = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
        }
        else{
          web3_1 = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
          
        }

        if (web3_1 !== null) {
          const contract = new web3_1.eth.Contract(
            mintContractABI,
            collections[i]?.contractAddress
          );
          console.log(contract.methods, "contract methods in created");

          console.log(addr,"address in created artist pop up");
          try {
            const data = await contract.methods._tokenIdCounter().call();
            console.log(data, "created data---------------",i);

            const parsedData = parseFloat(data.trim());
            if (!isNaN(parsedData)) {
              total += parsedData;
            } else {
              console.error(`Unable to parse data: ${data}`);
            }
          } catch (error) {
            console.error(`Error fetching data for contract ${collections[i]?.contractAddress}:`, error);
          }
        }
      }

      console.log(total, "total nfts");
      setCreated(total);
    }
  };



  useEffect(() => {
    getCreatedData();

  }, [address, userAddress, name, userAddress, collections])

  useEffect(() => {
    // alert(created)
    console.log(created, "created artworks");
  }, [created])

  const getCollected = async (addr) => {
    console.log(addr);


    if (chain && addr) {
      console.log(chain?.name, "chain name");

      const web3_1 = new Web3(window.ethereum);

      try {
        const accounts = await web3_1.eth.getAccounts();
        let MarketplaceAddress;
        if (chain.name === "Coston") {
          MarketplaceAddress = Marketplace_coston_contractAddress;
        } else if (chain.name === "Coston2") {
          MarketplaceAddress = Marketplace_coston2_contractAddress;
        } else {
          throw new Error("Unsupported chain name");
        }

        console.log(MarketplaceAddress, "Market place");
        const contract = new web3_1.eth.Contract(
          contractABI,
          MarketplaceAddress
        );

        console.log(contract.methods, "contract methods");

        const result = await contract.methods.userBuyRecord(addr).call();
        console.log(result, "result of collected");
        setCollected(result);
      } catch (error) {
        console.error("Error fetching user records:", error);
      }
    }
  };

  const [inCompleteProfile, setInCompleteProfile] = useState(true);

  // fetch all users data

  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      setGetAllUsersData(users);
      for (let a of users) {
        if (a?.id === address) {
          if (a.userName) {
            setInCompleteProfile(false);
          }
        }
      }

      setUserData(users);
    };
    fetchUserData();
  }, []);


  const getArtistNamebyAdress2 = (accountAddress) => {
    let artistName = "";

    getAllUsersData?.forEach((user) => {
      if (user?.id === accountAddress) {
        artistName = user?.userName;
      }
    });


    return artistName;
  };

  // get user data by address

  useEffect(() => {
    const fetchData = async () => {
      let rest = await getUserData(address);
      console.log(rest, "rest");

      setUserData(rest);
    };
    fetchData();
  }, [address]);

  // get user data by username from firebase

  useEffect(() => {
    console.log(name, "artist name");
    if (name === user) {
      getCollected(address);
      getCreatedData();
    } else {
      getCollected(userAddress)
      getCreatedData();
    }
  }, [address, userAddress, name, collections]);
  useEffect(() => {
    console.log(isWhiteListed, "is white lisoted");
  }, [isWhiteListed]);

  const [disable, setDisable] = useState(false);




  useEffect(() => {
    const isLogged = localStorage.getItem("catalystSigner");
    console.log(isLogged, "is loggeddddddddddd");

    console.log(address, "account address");
    if ((isLogged && address)) {

      setDisable(false);
    }
    else {

      setDisable(true);
    }
  }, [address, localStorage.getItem("catalystSigner")]);



  useState(() => {
    console.log(disable, "disable issue");

  }, [disable])
  const navigate = useNavigate();

  let username = localStorage?.getItem("userName");
  const [isFollowed, setIsFollowed] = useState(false);

  // fetch follow status from firebase based on username and artistname
  useEffect(() => {
    const check = async () => {
      await checkIfFollowed(address, userAddress);
    };
    check();
  }, [address, userAddress]);

  const fetchFollowStatus = async () => {

    ;
    const res = await getUserDataByUserName(name);
    console.log(res, "response of get user data by username");

    if (res) {
      setUserAddress(res?.documentId);
    }

    const result = await checkIfFollowed(address, res?.documentId);
    console.log(result);
    setIsFollowed(result);
  };

  // get Follow data from firebase
  const getFollowData = async () => {
    setTotalFollowers(0);
    let result;

    if (name === user) {
      result = await getFollowersByAddress(address);
    } else {
      result = await getFollowersByAddress(userAddress);
    }

    setTotalFollowers(result?.count > 0 ? result.count : 0);
  };



  useEffect(() => {
    fetchFollowStatus();
    getFollowData();

  }, [artistName, name, address, userAddress]);

  // if profile is not complete



  // username,
  // notificationType,
  // price = null,
  // artName,
  // transactionHash = null,
  // selectedBlockchain = null,
  // To = null,
  // isVisible = true,
  const followBtn = async () => {

    if (userAddress) {
      try {
        if (isFollowed) {
          await unfollowArtist(address, userAddress);
          toast.warn("Unfollowed Successfully");
          fetchFollowStatus();
        } else {
          console.log(address, userAddress);
          await handleFollow(address, userAddress);
          toast.success("Followed Successfully");
          console.log(address, userAddress,"address of follow artist");

          handleNotifications(
            userAddress,
            "newFollower",
            null,
            null,
            null,
            null,
            null,
            address

          )
          fetchFollowStatus();
        }

        // Update the isFollowed state after follow/unfollow action
        setIsFollowed(!isFollowed);

        getFollowData();
      } catch (error) {
        console.error("Error toggling follow status:", error);
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  useEffect(() => {
    fetchFollowStatus();
  }, []);
  const popupStyle = {
    // position: "absolute",
    // left: left || 0, // If left prop is not provided, default to 0
    // top: top || 0, // If top prop is not provided, default to 0
  };
  useEffect(() => {
    console.log(isWhiteListed, "is white lsited");
  }, [isWhiteListed]);
  const navigateToProfile = () => {
    if ((address && address.trim() !== "") || (userAddress && userAddress.trim() !== "")) {
      if (artistName === "You") {
        navigate(`/profile/${address}`);
      } else {
        navigate(`/profile/${userAddress}`);
      }
      // Reload the page for smooth reflection of changes
      window.location.reload();
    } else {
      console.error("Address or UserAddress is invalid");
    }
  }



  return (
    <div className="artist-popup" style={popupStyle}>
      <div className="pop-head">
        <div
          className="user-img"
          onClick={navigateToProfile}
        >
          {!userProfile ? (
            <img src={thumb} className="img-100 m-0  rounded-circle" />
          ) : (
            <img src={userProfile} className="img-100 m-0  rounded-circle" />
          )}
        </div>
        <p
          className="body-large pointer v-center"
          onClick={navigateToProfile}
        >
          {artistName === user
            ? "You"
            : artistName.length > 18
              ? artistName.substring(0, 18) + "..."
              : artistName}

          {isWhiteListed && <img src={verified} alt="verified" />}
        </p>
      </div>
      <div className="popup-body">
        <div className="created">
          {isWhiteListed ? (

            <>
              <label className="medium">Created</label>
              <label className="text-black">{created}</label>
            </>
          ) : (

            <>
              <label className="medium">Collected</label>
              <label className="text-black">{collected || 0}</label>
            </>
          )}
        </div>
        <div className="created">
          <label className="medium">Followers</label>
          <label className="text-black">{totalFollowers}</label>
        </div>
      </div>
      {isFollowed ? (
        <Button
          text={isFollowed ? "Following" : "Follow"}
          width="100%"
          height="36px"
          disabled={
            artistName == "You" ||
            artistName === user ||
            disable
          }
          className={
            !isFollowed
              ? "btn-prime btn-primary"
              : "btn-prime btn-secondary bg-transparent"
          }
          onClick={followBtn}
        />
      ) : (
        <Button
          text={isFollowed ? "Following" : "Follow"}
          width="100%"
          height="36px"
          disabled={
            artistName == "You" ||
            artistName === user ||
            disable
          }
          className={
            !isFollowed
              ? "btn-prime btn-primary"
              : "btn-prime btn-secondary bg-transparent"
          }
          onClick={followBtn}
        />
      )}
    </div>
  );
};
export default ArtistPopUp;
