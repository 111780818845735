import React, { useState, useEffect, useContext } from "react";
import Button from "../button";
import leftArrow from "../../assets/icon/chevron-left-small.svg";
import unchecked from "../../assets/icon/checkbox.svg";
import checked from "../../assets/icon/checkbox-selected.svg";
import { useNavigate } from "react-router-dom";
import UseWindowResize from "../../customHooks/useWindowResize";
import { toast } from "react-toastify";
import { addOrUpdateTopBannerText, getTopBannerText } from "../../firebase/firebase";
import { RefreshContext } from "../notificationContext";
import InputBox from "../shared/inputBox";

const Market = () => {
  const [spotActive, setSpotActive] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [banner, setBanner] = useState("");
  const [inputText, setInputText] = useState("");

  const navigate = useNavigate();
  const { isAdmin } = useContext(RefreshContext);

  useEffect(() => {
    if (!isAdmin) {
      navigate("/");
    }
  }, [isAdmin, navigate]);

  useEffect(() => {
    const fetchBanner = async () => {
      const bannerData = await getTopBannerText();
      setBanner(bannerData);
      setSpotActive(bannerData?.enable);
      setInputText(bannerData?.text);
    };
    fetchBanner();
  }, []);

  useEffect(() => {
    if (banner && (inputText !== banner?.text || spotActive !== banner?.enable)) {
      setChangesMade(true);
    } else {
      setChangesMade(false);
    }
  }, [inputText, spotActive, banner]);

  const handleSaveChanges = async () => {
    try {
      await addOrUpdateTopBannerText(inputText, spotActive);
      setChangesMade(false);
      toast.success("Changes saved successfully");
    } catch (error) {
      toast.error("Failed to save changes");
    }
  };

  UseWindowResize(1024, "/catalyst-phoenix-nexus");

  return (
    <div>
      <div className="market-promotion">
        <div className="admin-content-head v-center justify-content-between header-fixed">
          {/* Header for desktop */}
          <h3 className="fw-bold text-capitalize for-desktop">Marketing</h3>
          {/* Header for mobile */}
          <h6 className="fw-bold text-capitalize for-mobile" onClick={() => navigate(-1)}>
            <img src={leftArrow} alt="back" className="me-3" />
            Marketing
          </h6>

          <Button
            width="170px"
            height="47px"
            className={`br-30 ${changesMade ? "btn-primary" : "btn-ternary"}`}
            text="Save changes"
            disabled={!changesMade}
            onClick={handleSaveChanges}
          />
        </div>
        <div className="market-content mt-36">
          <p className="body-large fw-bold">Banner</p>
          <p className="body-medium mt-3 pt-2">
            Enabling this Banner temporarily shows a banner at the top of the landing page, above the navigation.
          </p>
          <div className="enable-banner mt-4 mb-5 ms-3">
            <label
              className="text-black no-text-transform v-center pointer d-inline-block"
              onClick={() => {
                setChangesMade(true);
                setSpotActive(!spotActive);
              }}
            >
              <img src={spotActive ? checked : unchecked} alt="checkbox" className="me-3" />
              Enable Banner
            </label>
          </div>

          <div className="spot-input-box">
            <InputBox
              label="Banner message"
              placeholder="Add banner message..."
              value={inputText}
              onChange={(e) => {
                setChangesMade(true);
                setInputText(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Market;
