import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import { Dropdown, Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import wFLR from "../../assets/icon/wrapped-FLR.svg";
import wSgb from "../../assets/icon/wrapped-SGB.svg";
import dropdown from "../../assets/icon/chevron-down-smallest.svg";
import close from "../../assets/icon/close.svg";
import tick from "../../assets/icon/tick-large-black.svg";
import auction from "../../assets/icon/auction.svg";
import listing from "../../assets/icon/listing.svg";
import offer from "../../assets/icon/offer.svg";
import sold from "../../assets/icon/sold.svg";
import transfer from "../../assets/icon/transfer.svg";
import verified from "../../assets/icon/verified-artist-small.svg";
import placeholder from "../../assets/images/profile-1.svg";
import exLink from "../../assets/icon/external-link-grey.svg";
import config from "../../config";
import ArtistPopUp from "../shared/artistpopup";
import {
  getCollectionHistoryByCollectionId,
  getCollectionByAddress,
} from "../../firebase/firebase";
import { useLocation } from "react-router-dom";
import { getAllUsers } from "../../firebase/firebase";
import { useAccount } from "wagmi";
import { Popover, Table } from "antd";
import Web3 from "web3";
import { useNavigate } from "react-router-dom";
import mintContractABI from "../../abis/SafeMint/abi.json";
import marketplaceContractABI from "../../abis/marketplace.json";
import Backend_url from "../../herokuUrlConfig";
import { act } from "react";
const Activity = () => {

  const { marketplace, erc20 } = config;
  console.log(marketplace);
  console.log(erc20);



  const Marketplace_coston_contractAddress =
    marketplace.coston;
  const Marketplace_coston2_contractAddress =
    marketplace.coston2;
  const contractABI = marketplaceContractABI;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;

  const web3 = new Web3();
  const [show, setShow] = useState(false);
  const { address } = useAccount();
  // click handles
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  const [activeOffer, setActiveOffer] = useState("currentOffer");
  const [priceShow, setPriceShow] = useState(false);
  const [showmore, setShowmore] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [selectedItem, setSelectedItem] = useState("All"); // Initial value
  const [data, setData] = useState([]); // Initial value
  const [activity, setActivity] = useState(data); // filtered value

  const location = useLocation();

  // get collection history from firebase

  useEffect(() => {
    getCollectionHistoryByCollectionId(location.pathname.split("/")[2]).then(
      (res) => {
        console.log(res, "response");

        // Sort the res array by timestamp in descending order
        res.sort((a, b) => {
          // Sort timestamps in descending order
          return b.timestamp.seconds - a.timestamp.seconds;
        });

        // Set sorted data to state variables
        setData(res);


        setActivity(res); // Assuming setActivity is meant to set the same data as setData
      }
    );
  }, []);

  const handleActivityHeading = () => setPriceShow(!priceShow);

  const handleMoreContent = (index) => {
    setShowmore((prevState) => ({
      ...prevState,
      [index]: !prevState[index] || false,
    }));

    setShowContent((prevState) => ({
      ...prevState,
      [index]: !prevState[index] || false,
    }));
  };

  const handleItemClick = (value, show) => {
    setSelectedItem(show);
    if (value === "All") {
      console.log(data, "data in activiryyyyyyyyyy");
      setActivity(data);
    } else {
      for (let a of data) {
        if (a.action == value) {
        }
      }

      const filteredData = data.filter((item) => item.action === value);
      console.log(filteredData, "filtered data");
      setActivity(filteredData);
    }
  };

  const handleIPriceTick = (tick) => {
    setActiveOffer(tick);
  };

  // Function to format Ethereum address to 0x23....234 format
  const formatEthereumAddress = (address) => {
    if (!address) {
      return ""; // handle empty address case if needed
    }

    // Check if the address is already in the correct format
    if (address.startsWith("0x")) {
      address = address.slice(2); // Remove '0x' prefix for manipulation
    }

    // Get the first 4 and last 3 characters of the address
    const shortenedAddress = address.slice(0, 4) + "..." + address.slice(-3);

    // Add '0x' prefix to the shortened address
    return "0x" + shortenedAddress;
  };

  // calcualte time

  function timeAgo(timestamp) {
    const currentDate = new Date();
    const providedDate = new Date(timestamp * 1000); // Convert seconds to milliseconds
  
    const timeDifference = currentDate - providedDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);
  
    if (minutes <= 0) {
      return "0 minutes ago"; // Return 0 if the value is negative or zero
    } else if (minutes < 60) {
      return `${minutes} ${minutes === 1 ? "minute" : "minutes"} ago`;
    } else if (hours < 24) {
      return `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
    } else if (days < 30) {
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (months < 12) {
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else {
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    }
  }
  
  

  const tooltip1 = (artPrice) => {
    return <Tooltip id="tooltip1">{artPrice}</Tooltip>;
  };

  const tooltip2 = (chainName) => {
    return <Tooltip id="tooltip2">Chain: {chainName}</Tooltip>;
  };

  const [userData, setUserData] = useState([]);

  // get user from local storage

  const checkUser = async () => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUserData(user);
    }
  };

  // get all user data from firebase
  useEffect(() => {
    checkUser();
    const fetchUserData = async () => {
      const users = await getAllUsers();
      console.log("users", users);
      setUserData(users);
    };
    fetchUserData();
  }, []);

  // get artist name by address

  const getArtistNamebyAdress = (accountAddress) => {
    let artistName = accountAddress;

    if (address === accountAddress) {
      return "You";
    } else {
      userData?.forEach((user) => {
        if (user?.id === accountAddress) {
          artistName = user?.userName;
        }
      });
    }
    console.log(artistName, "artist name");
    return artistName;
  };

  // get artist image by address

  const getArtistImage = (accountAddress) => {
    let artistImage = placeholder;
    userData?.forEach((user) => {
      if (user?.id === accountAddress) {
        if (user?.image) {
          artistImage = user?.image;
        } else {
          artistImage = placeholder;
        }
      }
    });

    return artistImage;
  };

  const convertWeiToEther = (priceInWei) => {
    if (priceInWei) {
      try {
        // Convert the price from wei to ether, ensuring the input is a string
        const priceInEther = web3.utils.fromWei(priceInWei.toString(), "ether");
        return priceInEther;
      } catch (error) {
        console.error('Error converting Wei to Ether:', error);
        return null;
      }
    }
    return null; // Return null or handle cases where priceInWei is falsy
  };

  useEffect(() => {
    console.log(activity, "activity");
  }, [activity]);

  const navigateToExplorer = (address, selectedBlockchain) => {
    console.log(selectedBlockchain, "selected blockchain");
    console.log(address, "address");

    if (selectedBlockchain === "Coston") {
      window.open(
        `https://coston-explorer.flare.network/tx/${address}`,
        "_blank"
      );
    } else {
      window.open(
        `https://coston2-explorer.flare.network/tx/${address}`,
        "_blank"
      );
    }
  };


  const navigateToArt = async (item) => {
    console.log(item);


    const web3_1 = new Web3(window.ethereum);

    // const accounts = await web3.eth.getAccounts();
    if (item.artworkUri && web3_1 != null) {
      let MarketplaceAddress;
      if (item?.artworkUri?.selectedBlockchain === "Coston") {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (item?.artworkUri?.selectedBlockchain === "Coston2") {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }


      const contract = new web3_1.eth.Contract(
        marketplaceContractABI,
        MarketplaceAddress
      );
      console.log(contract.methods, "contract methods in getlisted function");
      const data = await contract.methods.getAllListedNfts().call();
      console.log(data, "get listed nfts data-==============");



      data[1]?.forEach((nft, index) => {

        // Adding index to track offer listing position
        if (nft?.uriData === item?.uri) {
          console.log(nft);
          console.log(nft?.listedData?.tokenId);
          navigate(
            `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.artworkUri?.selectedCollectionId}/${nft?.listedData?.tokenId}`
          );

        }
      });


    } else {
      console.log("error");
    }

  }



  const navigateToSingleArtwork = (item) => {

    if (item?.action === "Mint") {
      // alert("mint");
      navigateToArt(item);
      return;
    } else {
      console.log(item, "item");
      navigate(
        `/artwork/${item?.artworkUri?.selectedBlockchain}/${item?.artworkUri?.selectedCollectionId}/${item?.tokenId}`
      );
    }
  };


  const columns = [
    // all activity
    {
      title: "ALL ACTIVITY",
      key: "ALL ACTIVITY",
      width: "200px",
      render: (_, record) => (
        <div className="d-flex align-items-center">
          <div className="art">
            <label
              onClick={() => {
                navigateToExplorer(
                  record?.transactionHash,
                  record?.artworkUri?.selectedBlockchain ||
                  record?.artworkUri?.metadata?.data?.selectedBlockchain
                );
              }}
              className={` small text-black text-capitalize v-center activity-box  m-0  bg-auction  ${record.action === "listedOffer"
                ? "bg-auction"
                : record.action === "sold"
                  ? "bg-sold"
                  : record.action === "listedSale"
                    ? "bg-listing"
                    : record.action === "offer"
                      ? "bg-offer"
                      : record.action === "transfer"
                        ? "bg-transfer"
                        : record.action === "Mint"
                          ? "bg-transfer"
                          : ""
                }`}
            >
              <img
                src={
                  record.action === "offer"
                    ? auction
                    : record.action === "sold"
                      ? sold
                      : record.action === "listedSale"
                        ? listing
                        : record.action === "transfer"
                          ? transfer
                          : record.action === "Mint"
                            ? listing
                            : record.action === "claimNft" ?
                              listing
                              : ""
                }
                alt=""
                className="img-12 me-1"
              />
              {record?.action === "listedSale"
                ? "Listing"
                : record?.action === "offer"
                  ? "Offer"
                  : record.action}
            </label>
          </div>
        </div>
      ),
    },

    // ARTWORK
    {
      title: "ARTWORK",
      key: "ARTWORK",
      width: "350px",
      render: (_, record) => (
        <div className="art d-flex align-items-center">
          <div
            onClick={() => {
              navigateToSingleArtwork(record);
            }}
            className="art-img p-0 border-0"
          >
            {record.action === "Mint" ? (
              <img
                src={
                  record?.artworkUri?.image || record?.artworkUri?.previewImg
                }
                alt="artwork image"
                className="img-100"
              />
            ) : (
              <div
                onClick={() => {
                  navigateToSingleArtwork(record);
                }}
              >
                {" "}
                {record?.artworkUri?.metadata?.data?.image ||
                  record?.artworkUri?.image ? (
                  <img
                    src={
                      record.action === "listedOffer"
                        ? record?.artworkUri?.metadata?.data?.image
                        : record?.artworkUri?.image
                    }
                    alt="artwork image"
                    className="img-100"
                  />
                ) : (
                  <img
                    src={
                      record.action === "listedOffer"
                        ? record?.artworkUri?.metadata?.data?.previewImg
                        : record?.artworkUri?.previewImg
                    }
                    alt="artwork image"
                    className="img-100"
                  />
                )}{" "}
              </div>
            )}
          </div>
          <label
            onClick={() => {
              navigateToSingleArtwork(record);
            }}
            className="text-black no-text-transform pointer "
          >
            {record.action === "listedOffer" ? (
              <>
                {record?.artworkUri?.metadata?.data?.artName.length > 18 ? (
                  <>
                    {record?.artworkUri?.metadata?.data?.artName.substring(
                      0,
                      18
                    )}
                    ...
                  </>
                ) : (
                  <>{record?.artworkUri?.metadata?.data?.artName} </>
                )}
              </>
            ) : (
              <>
                {/* record?.artworkUri?.artName */}

                {record?.artworkUri?.artName?.length > 18 ? (
                  <>
                    <Popover
                      title={record?.artworkUri?.artName}
                      overlayClassName="table-popover"
                    >
                      {record?.artworkUri?.artName.substring(0, 18)}...
                    </Popover>
                  </>
                ) : (
                  <>{record?.artworkUri?.artName}</>
                )}
              </>
            )}
          </label>
        </div>
      ),
    },

    // From
    {
      title: "From",
      key: "From",
      width: "200px",
      render: (_, record) => (
        <div>
          <div className="v-center">
            <>
              <div
                onClick={() => {
                  navigate(`/profile/${record?.from}`);
                }}
                className="art-img p-0 border-0 img-26"
              >
                <img
                  src={getArtistImage(record?.from)}
                  alt="art"
                  className="img-100"
                />
              </div>
              <Popover
                placement="top"
                trigger="hover"
                content={
                  <ArtistPopUp
                    userProfile={getArtistImage(record?.from)}
                    artistName={getArtistNamebyAdress(record?.from)}
                    verified={verified}
                  />
                }
              >
                <p
                  onClick={() => {
                    navigate(`/profile/${record?.from}`);
                  }}
                  className="ms-2 fw-bold text-black no-text-transform spotlight-page-content pointer"
                >
                  {getArtistNamebyAdress(record?.from).length > 16
                    ? getArtistNamebyAdress(record?.from).substring(0, 16) +
                    "..."
                    : getArtistNamebyAdress(record?.from)}
                </p>
              </Popover>
            </>
          </div>
        </div>
      ),
    },

    // To
    {
      title: "to",
      key: "to",
      width: "150px",
      render: (_, record) => (
        <>
          <div className="v-center">
            {record.action === "sold" || record.action === "transfer" || record.action === "claimNft" ? (
              // Render this block if action is 'sold' or 'transfer'
              <>
                <div className="art-img p-0 border-0 img-26">
                  <img
                    src={getArtistImage(record?.to)}
                    alt="art"
                    className="img-100"
                  />
                </div>

                <Popover
                  placement="top"
                  trigger="hover"
                  content={
                    <ArtistPopUp
                      userProfile={getArtistImage(record?.to)}
                      artistName={getArtistNamebyAdress(record?.to)}
                      verified={verified}
                    />
                  }
                >
                  <p
                    onClick={() => {
                      navigate(`/profile/${record?.to}`);
                    }}
                    className="ms-2 fw-bold text-black no-text-transform spotlight-page-content pointer"
                  >
                    {getArtistNamebyAdress(record?.to).length > 16
                      ? getArtistNamebyAdress(record?.to).substring(0, 16) +
                      "..."
                      : getArtistNamebyAdress(record?.to)}
                  </p>
                </Popover>
              </>
            ) : (
              // Render this block for other actions
              <p>--</p>
            )}
          </div>
        </>
      ),
    },

    // PRICE
    {
      title: "PRICE",
      key: "PRICE",
      width: "150px",
      render: (_, record) => (
        <div className="v-center c6 offerAction">

          {(record.action === "offer" || record.action === "listedSale" || record.action === "sold"||record.action === "claimNft") ? (

            <label className="text-black">
              <OverlayTrigger
                placement="top"
                overlay={tooltip1(
                  record.action === "listedSale"
                    ? record.price
                    : record?.price / 1000000000000000000
                )}
              >
                <img
                  src={
                    record.artworkUri?.selectedBlockchain === "Coston"
                      ? wSgb
                      : wFLR
                  }
                  alt=""
                  className="me-2"
                />
              </OverlayTrigger>
              {record.action === "listedSale"
                ? record.price
                : record?.price / 1000000000000000000}
              <span className="body-extra-small ms-2">
                {record.artworkUri?.selectedBlockchain === "Coston"
                  ? "SGB"
                  : "FLR"}
              </span>
            </label>

          ) : (
            <p>--</p>
          )}
        </div>
      ),
    },

    // TIME
    {
      title: "TIME",
      key: " TIME",
      width: "150px",
      render: (_, record) => (
        <div className="v-center c6  offerAction">
          <label className="text-black no-text-transform">
            {timeAgo(record?.timestamp?.seconds) || 0}
          </label>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="offers activities analytics">
        <div className="create-artwork mt-0 pt-2 bg-transparent">
          <Dropdown className="select-collection">
            <Dropdown.Toggle id="dropdown-basic">
              {selectedItem}
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              <Dropdown.Item onClick={() => handleItemClick("All", "All")}>
                All
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleItemClick("offer", "Offer")}
              >
                Offer
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleItemClick("listedSale", "Listing")}
              >
                Listing
              </Dropdown.Item>

              <Dropdown.Item onClick={() => handleItemClick("sold", "Sold")}>
                Sold
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => handleItemClick("transfer", "Transfer")}
              >
                Transfer
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="offer-table mt-4  hide-on-desktop">
          {/* <div className="offer-table-content hide-on-1024">
            <div className="offer-table-head ">
              <label className="small v-center c1 ">all activity</label>
              <label className="small v-center c2  ">artwork</label>
              <label className="small v-center  c4 ">from</label>
              <label className="small v-center  c4 ">to</label>
              <label className="small  v-center c5  ">price</label>
              <label className="small v-center  c6  ">time</label>
            </div>

            <div className="offer-table-body ">
              {activity.map((item, index) => {
                console.log(item, "item in activity");
                return (
                  <div
                    className={`offer-table-content    ${
                      item.offerStatus === "Accept" ? "" : " "
                    }`}
                    key={index}
                  >
                    <div className="art">
                      <label
                        onClick={() => {
                          navigateToExplorer(
                            item?.transactionHash,
                            item?.artworkUri?.selectedBlockchain ||
                              item?.artworkUri?.metadata?.data
                                ?.selectedBlockchain
                          );
                        }}
                        className={` small text-black text-capitalize v-center activity-box  m-0  bg-auction  ${
                          item.action === "listedOffer"
                            ? "bg-auction"
                            : item.action === "sold"
                            ? "bg-sold"
                            : item.action === "listedSale"
                            ? "bg-listing"
                            : item.action === "listedOffer"
                            ? "bg-offer"
                            : item.action === "tranfer"
                            ? "bg-transfer"
                            : item.action === "Mint"
                            ? "bg-transfer"
                            : ""
                        }`}
                      >
                        <img
                          src={
                            item.action === "listedOffer"
                              ? auction
                              : item.action === "sold"
                              ? sold
                              : item.action === "listedSale"
                              ? listing
                              : item.action === "tranfer"
                              ? transfer
                              : item.action === "Mint"
                              ? listing
                              : ""
                          }
                          alt=""
                          className="img-12 me-1"
                        />
                        {item?.action === "listedSale"
                          ? "Listing"
                          : item?.action === "listedOffer"
                          ? "Offer"
                          : item.action}
                      </label>
                    </div>

                    <div className="art">
                      <div
                        onClick={() => {
                          navigateToSingleArtwork(item);
                        }}
                        className="art-img p-0 border-0"
                      >
                        {item.action === "Mint" ? (
                          <img
                            src={
                              item?.artworkUri?.image ||
                              item?.artworkUri?.previewImg
                            }
                            alt="artwork imageee"
                            className="img-100"
                          />
                        ) : (
                          <div>
                            {" "}
                            {item?.artworkUri?.metadata?.data?.image ||
                            item?.artworkUri?.image ? (
                              <img
                                src={
                                  item.action === "listedOffer"
                                    ? item?.artworkUri?.metadata?.data?.image
                                    : item?.artworkUri?.image
                                }
                                alt="artwork image"
                                className="img-100"
                              />
                            ) : (
                              <img
                                src={
                                  item.action === "listedOffer"
                                    ? item?.artworkUri?.metadata?.data
                                        ?.previewImg
                                    : item?.artworkUri?.previewImg
                                }
                                alt="artwork image"
                                className="img-100"
                              />
                            )}{" "}
                          </div>
                        )}
                      </div>

                      <label
                        onClick={() => {
                          navigateToSingleArtwork(item);
                        }}
                        className="text-black "
                      >
                        {item.action === "listedOffer"
                          ? item?.artworkUri?.metadata?.data?.artName
                          : item?.artworkUri?.artName}
                      </label>
                    </div>

                    <div className="v-center">
                      <>
                        <div className="art-img p-0 border-0 img-26">
                          <img
                            src={getArtistImage(item?.from)}
                            alt="art"
                            className="img-100"
                          />
                        </div>

                        <Popover
                          placement="top"
                          trigger="hover"
                          content={
                            <ArtistPopUp
                              userProfile={getArtistImage(item?.from)}
                              artistName={getArtistNamebyAdress(item?.from)}
                              verified={verified}
                            />
                          }
                        >
                          <p
                            onClick={() => {
                              navigate(`/profile/${item?.from}`);
                            }}
                            className="ms-2 fw-bold text-black no-text-transform spotlight-page-content pointer"
                          >
                            {getArtistNamebyAdress(item?.from)}
                          </p>
                        </Popover>
                      </>
                    </div>

                    <div className="v-center">
                      {item.action === "sold" || item.action === "transfer" ? (
                        // Render this block if action is 'sold' or 'transfer'
                        <>
                          <div className="art-img p-0 border-0 img-26">
                            <img
                              src={getArtistImage(item?.to)}
                              alt="art"
                              className="img-100"
                            />
                          </div>

                          <Popover
                            placement="top"
                            trigger="hover"
                            content={
                              <ArtistPopUp
                                userProfile={getArtistImage(item?.to)}
                                artistName={getArtistNamebyAdress(item?.to)}
                                verified={verified}
                              />
                            }
                          >
                            <p
                              onClick={() => {
                                navigate(`/profile/${item?.to}`);
                              }}
                              className="ms-2 fw-bold text-black no-text-transform spotlight-page-content pointer"
                            >
                              {getArtistNamebyAdress(item?.to)}
                            </p>
                          </Popover>
                        </>
                      ) : (
                        <p>--</p>
                      )}
                    </div>

                    <div className="v-center c6  offerAction">
                      <label className="text-black ">
                        <OverlayTrigger
                          placement="top"
                          overlay={tooltip1(
                            item.action === "listedSale"
                              ? item.price
                              : item?.price / 100000000000000000
                          )}
                        >
                          <img
                            src={
                              item.artworkUri?.selectedBlockchain === "Coston"
                                ? wSgb
                                : wFLR
                            }
                            alt=""
                            className="me-2"
                          />
                        </OverlayTrigger>
                        {item.action === "listedSale"
                          ? item.price
                          : item?.price / 100000000000000000}
                        <span className="body-extra-small ms-2">
                          {item.artworkUri?.selectedBlockchain === "Coston"
                            ? "SGB"
                            : "FLR"}
                        </span>
                      </label>
                    </div>

                    <div className="v-center c6  offerAction">
                      <label className="text-black no-text-transform">
                        {timeAgo(item?.timestamp?.seconds) || 0}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div> */}

          <div className="mb-offer-table-content w-100">
            <div className="offer-table-head bb1 v-center justify-content-between pb-2 ">
              <label className="small v-center c1 ">all activity</label>
              <label className="small v-center  c6  text-black ">Details</label>
            </div>
            <div className="mb-offer-table-body ">
              {activity.map((item, index) => {
                console.log(activity, "activityyyyyyyyyyyyyyyy");
                return (
                  <div
                    className="row-content d-flex  mh-76 bb1 flex-column"
                    key={index}
                  >
                    <div className="top-content v-center justify-content-between w-100">
                      <div className="left-content v-center">
                        {item.action === "Mint" ? (
                          <img
                            src={
                              item?.artworkUri?.image ||
                              item?.artworkUri?.previewImg
                            }
                            alt="artwork image"
                            className="img-48"
                            onClick={() => {
                              navigateToSingleArtwork(item);
                            }}
                          />
                        ) : (
                          <div
                            onClick={() => {
                              navigateToSingleArtwork(item);
                            }}
                          >
                            {" "}
                            {item?.artworkUri?.metadata?.data?.image ||
                              item?.artworkUri?.image ? (
                              <img
                                src={
                                  item.action === "listedOffer"
                                    ? item?.artworkUri?.metadata?.data?.image
                                    : item?.artworkUri?.image
                                }
                                alt="artwork image"
                                className="img-48"
                              />
                            ) : (
                              <img
                                src={
                                  item.action === "listedOffer"
                                    ? item?.artworkUri?.metadata?.data
                                      ?.previewImg
                                    : item?.artworkUri?.previewImg
                                }
                                alt="artwork image"
                                className="img-48"
                              />
                            )}{" "}
                          </div>
                        )}
                        <div className="d-flex flex-column ms-2">
                          <label
                            onClick={() => {
                              navigateToSingleArtwork(item);
                            }}
                            className="text-black "
                          >
                            {item.action === "listedOffer"
                              ? item?.artworkUri?.metadata?.data?.artName
                              : item?.artworkUri?.artName}
                          </label>
                          <label
                            className="small fw-bold"
                            onClick={() => handleMoreContent(index)}
                          >
                            {showmore[index] ? "SHOW less" : "show more"}
                            <img
                              src={dropdown}
                              alt="dropwdown"
                              className="border-0 p-0"
                              style={{
                                transform: showmore[index]
                                  ? "rotate(180deg)"
                                  : "",
                              }}
                            />
                          </label>
                        </div>
                      </div>

                      <div className="right-content">
                        <p className="body-small text-capitalize  v-center fw-semibold">
                          <img
                            src={
                              item.action === "listedOffer"
                                ? auction
                                : item.action === "sold"
                                  ? sold
                                  : item.action === "listedSale"
                                    ? listing
                                    : item.action === "transfer"
                                      ? transfer
                                      : item.action === "Mint"
                                        ? listing
                                        : item.action === "claimNft" ?
                                          transfer
                                          : ""
                            }
                            alt=""
                            className="img-12 me-1"
                          />
                          {item?.action === "listedSale"
                            ? "Listing"
                            : item?.action === "listedOffer"
                              ? "Offer"
                              : item.action}
                        </p>

                        <div className="currency">
                          <img
                            src={
                              item.artworkUri?.selectedBlockchain === "Coston"
                                ? wSgb
                                : wFLR
                            }
                            alt="wflr"
                            className="img-14"
                          />
                          <label className="text-black fw-semibold medium">
                            {item.action === "listedSale"
                              ? item.price
                              : item?.price / 100000000000000000}{" "}
                            <span className="body-extra-small">
                              {item.artworkUri?.selectedBlockchain === "Coston"
                                ? "SGB"
                                : "FLR"}
                            </span>
                          </label>
                        </div>
                        <label className="small fw-semibold no-text-transform">
                          {timeAgo(item?.timestamp?.seconds) || 0}
                        </label>
                      </div>
                    </div>

                    {showmore[index] ? (
                      <div className="btm-content w-100 mt-2 v-center justify-content-between ">
                        {/* from */}

                        <div>
                          <label htmlFor="" className="small">
                            from
                          </label>
                          <p className="body-medium fw-semibold">
                            <p
                              onClick={() => {
                                navigate(`/profile/${item?.from}`);
                              }}
                              className="ms-2 fw-bold text-black no-text-transform spotlight-page-content pointer"
                            >
                              {getArtistNamebyAdress(item?.from).substring(
                                0,
                                15
                              )}
                            </p>
                          </p>
                        </div>

                        {/* To */}
                        <div>
                          <label htmlFor="" className="small">
                            to
                          </label>
                          {item.action === "sold" ||
                            item.action === "transfer" || item.action === "claimNft" ? (
                            <p className="body-medium fw-semibold">
                              {
                                <p
                                  onClick={() => {
                                    navigate(`/profile/${item?.from}`);
                                  }}
                                  className="ms-2 fw-bold text-black no-text-transform spotlight-page-content pointer"
                                >
                                  {getArtistNamebyAdress(item?.to)}
                                </p>
                              }
                            </p>
                          ) : (
                            <p>--</p>
                          )}
                        </div>

                        {/* tx hash */}
                        <div>
                          <label htmlFor="" className="small">
                            tx hash
                          </label>
                          <p className="body-medium fw-semibold">
                            {item?.transactionHash
                              ? `${item?.transactionHash.slice(
                                0,
                                6
                              )}...${item?.transactionHash.slice(-4)}`
                              : ""}
                            <img src={exLink} alt="" className="img-14 ms-2" />
                          </p>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="nft-table mt-4 hide-on-mobile">
          <Table
            columns={columns}
            dataSource={activity}
          // onRow={(record) => {
          //   return {
          //     onClick: () => {
          //       navigateToSingleArtwork(record);
          //     },
          //   };
          // }}
          />
        </div>
      </div>

      <Offcanvas
        show={priceShow}
        onHide={handleActivityHeading}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu">
          <div className="menu-head">
            <label className="text-black">change</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={handleActivityHeading}
              />
            </div>
          </div>

          <div className="share">
            {priceOptions.map((option) => (
              <label
                key={option.value}
                className={`no-text-transform h-64 text-black ${activeOffer === option.value ? "fw-bold" : "fw-normal"
                  }`}
                onClick={() => handleIPriceTick(option.value)}
              >
                {option.label}
                <img
                  src={tick}
                  alt="tick"
                  className={`${activeOffer === option.value
                    ? "active opacity-100"
                    : "opacity-0"
                    }`}
                />
              </label>
            ))}
          </div>
        </div>
      </Offcanvas>
    </div>
  );
};
const priceOptions = [
  { value: "all activity", label: "All Activity" },
  { value: "offerDiffernce", label: "Difference" },
  { value: "offerDate", label: "Date" },
  { value: "offerFrom", label: "From" },
  { value: "offerAction", label: "Action" },
];
export default Activity;
