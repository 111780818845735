import React, { useEffect, useState, useContext } from "react";
import "../style/main.scss";
import Button from "../components/button";
import { toast } from "react-toastify";
import user from "../assets/images/face-7.png";
// import thumb from "../assets/images/artwork-example-5.png";
import website from "../assets/icon/website-white.svg";
import instagram from "../assets/icon/instagram.svg";
import discord from "../assets/icon/discord.svg";
import twitter from "../assets/icon/twitter.svg";
import more from "../assets/icon/arrow-angle-right-grey.svg";
import FooterV2 from "../components/footerV2";
import {
  getSpotlightUser,
  getUserDataByUserName,
  handleFollow,
  checkIfFollowed,
  unfollowArtist,
  getImageByUsername,
} from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import thumb from "../assets/icon/profile-picture.svg";
import { useAccount } from "wagmi";
import { NFTContext } from "../components/context/appContext";
import Footer from "../components/footer";

const Spotlight = ({ isLoggedIn, setIsSignInModalOpen }) => {
  const { address } = useAccount();

  const [userData, setUserData] = useState([]);
  const [userDataByUserName, setUserDataByUserName] = useState([]);
  let username = localStorage?.getItem("userName");
  const [isFollowed, setIsFollowed] = useState(false);
  const [artistName, setArtistName] = useState(userData?.spotUsername);
  const [artistImage, setArtistImage] = useState(null);
  const { listedNfts } = useContext(NFTContext);
  const [spotUserAddress, setSpotUserAddress] = useState(null);

  const [spotLightUserArts, setSpotLightUserArts] = useState([]);

  useEffect(() => {
    console.log(listedNfts, "nft list data");
    if (listedNfts && listedNfts?.length > 0 && spotUserAddress) {
      const filteredArts = listedNfts?.filter(
        (nft) => nft.data.newOwner === spotUserAddress
      );
      const artsToShow = filteredArts.slice(0, 2);
      console.log(artsToShow, "art to show");

      setSpotLightUserArts(artsToShow);
    }
  }, [listedNfts, spotUserAddress]);

  const navigate = useNavigate();

  const profile = "OBAID324";

  const getImage = async (username) => {
    return await getImageByUsername(username);
  };
  useEffect(() => {
    const fetchData = async () => {
      const users = await getSpotlightUser();
      console.log("User Data", users);
      setUserData(users[0]);
    };
    fetchData();
    console.log("userData", userData);
  }, []);

  useEffect(() => {
    if (userData?.spotUsername) {
      getUserNameForProfile();
    }
  }, [userData]);

  const getUserNameForProfile = async () => {
    console.log(userData?.spotUsername);
    const userNamedata = await getUserDataByUserName(userData?.spotUsername);
    setSpotUserAddress(userNamedata?.documentId);
    console.log(userNamedata);
    setUserDataByUserName(userNamedata);
  };

  useEffect(() => {
    // alert(userData?.spotUsername);
    setArtistName(userData?.spotUsername);
  }, [userData]);

  useEffect(() => {
    // Fetch initial follow status when the component mounts
    const fetchInitialFollowStatus = async () => {
      const initialStatus = await fetchFollowStatus(username, artistName);
      setIsFollowed(initialStatus);
    };
    fetchInitialFollowStatus();
  }, [userData]); // Empty dependency array to ensure this effect runs only once when the component mounts

  const fetchFollowStatus = async (username) => {
    let artist = userData?.spotUsername;
    console.log(username);
    let image = await getImageByUsername(username);
    console.log(image);
    setArtistImage(image);
    console.log(artist);

    const result = await checkIfFollowed(username, artist);
    console.log(result);
    setIsFollowed(result);
    return result;
  };

  useEffect(() => {
    fetchFollowStatus(username);
  }, [userData]);

  const handleFollowToggle = async () => {
    if (userData) {
      let artistName = userData?.spotUsername;

      if (isFollowed) {
        await unfollowArtist(username, artistName);
        toast.warn("Unfollowed Successfully");
      } else {
        await handleFollow(username, artistName, "true");
        toast.success("Followed Successfully");
      }
      setIsFollowed(!isFollowed); // Toggle the follow status
    }
  };

  console.log(userData, "userData");

  const handleSeeMore = () => {
    navigate(`/profile/${userData?.spotAddress}`, {
      state: { tab: "created" },
    });
  };
  return (
    <div>
      <div className="spotlight-page h-center site-container">
        {userData?.spotActive ? (
          <div className="spotlight-page-content   ">
            <p className="text-white">SPOTLIGHT ARTIST</p>
            <h4 className="medium-head text-white mt-3 pb-4">
              {userData?.mainTitle}
            </h4>

            {userData?.spotUsername && (
              <div className="profile-box br-30 v-center justify-content-between">
                <div className="left v-center">
                  <div className="profile-img">
                    {artistImage ? (
                      <img
                        src={artistImage}
                        alt="profile"
                        className="img-45"
                        style={{ borderRadius: "50%" }}
                      />
                    ) : (
                      <img src={thumb} alt="user" className="img-45" />
                    )}
                  </div>
                  <h6 className="fw-bold text-white ms-2 text-normal">
                    @{userData?.spotUsername}
                  </h6>
                </div>
                <div className="right pe-1">
                  <Button
                    text="View profile"
                    className="btn-prime text-white btn-primary bg-transparent"
                    width="126px"
                    height="36px"
                    onClick={() => {
                      if (isLoggedIn && address) {
                        navigate(`/profile/${userData?.spotAddress}`);
                      } else {
                        setIsSignInModalOpen(true); // Setting to true instead of toggling for clarity
                      }
                    }}
                  />
                </div>
              </div>
            )}
            <div className="artist-about-box  v-center justify-content-between">
              <div className="left">
                <a href="#" className="v-center">
                  <img src={website} alt="site" className="img-30" />
                  <p className="text-white ms-2">
                    <a
                      style={{ color: "white" }}
                      target="_blank"
                      href={`${userData?.spotWebsite}`}
                    >
                      {userData?.spotWebsite}
                    </a>
                  </p>
                </a>
              </div>
              <div className="right">
                <div className="social-links">
                  {userData?.spotInstagram && (
                    <a target="_blank" href={userData?.spotInstagram}>
                      <img
                        src={instagram}
                        alt="instagram"
                        className="invert1"
                      />
                    </a>
                  )}
                  {userData?.spotDiscord && (
                    <a target="_blank" href={userData?.spotDiscord}>
                      <img src={discord} alt="discord" className="invert1" />
                    </a>
                  )}

                  {userData.spotTwitter && (
                    <a target="_blank" href={userData?.spotTwitter}>
                      <img src={twitter} alt="twitter" className="invert1" />
                    </a>
                  )}
                </div>
              </div>
            </div>
            {userData?.spotBio && (
              <div className="artist-about">
                <h5 className="italic-head no-text-transform text-white fw-normal mb-50">
                  {userData?.spotBio}
                </h5>
              </div>
            )}
            {userData?.spotFeaturedImage1 && (
              <div className="artwork-box">
                <img
                  src={userData?.spotFeaturedImage1}
                  alt="thumbnail"
                  className="img-100"
                />
                <p className="body-large mt-3 pt-2 text-white fw-bold">
                  {userData?.spotFeaturedName1}
                </p>
                <label
                  onClick={() =>
                    window.open(userData.spotFeaturedLink1, "_blank")
                  }
                  htmlFor=""
                  className="small v-center pointer mt-2"
                >
                  view artwork <img src={more} alt="arrow" className="img-12" />
                </label>
              </div>
            )}

            {userData?.spotUsername && (
              <div className="user-ico v-center">
                {/* <img src={user} alt="user" className="img-45" /> */}
                {artistImage ? (
                  <img
                    src={artistImage}
                    alt="profile"
                    className="img-45"
                    style={{ borderRadius: "50%" }}
                  />
                ) : (
                  <img src={thumb} alt="user" className="img-45" />
                )}
                <h4 className="medium-head ms-2 text-white">
                  @{userData?.spotUsername}
                </h4>
              </div>
            )}

            {userData?.spotHeader1 && userData?.spotFeaturedImage2 && (
              <div className="q-box mt-60">
                <h6 className="text-white fw-normal text-italic pb-4 bb1 ">
                  {userData?.spotHeader1}
                </h6>
                <p
                  className="body-large  fw-normal details text-white mt-60"
                  style={{
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                >
                  {userData?.spotSection1}
                </p>

                <div className="artwork-box">
                  <img
                    src={userData?.spotFeaturedImage2}
                    alt="thumbnail"
                    className="img-100"
                  />
                  <p className="body-large mt-3 pt-2 text-white fw-bold">
                    {userData?.spotFeaturedName2}
                  </p>
                  <label
                    onClick={() =>
                      window.open(userData.spotFeaturedLink2, "_blank")
                    }
                    htmlFor=""
                    className="small v-center pointer mt-2"
                  >
                    view artwork{" "}
                    <img src={more} alt="arrow" className="img-12" />
                  </label>
                </div>
              </div>
            )}

            {console.log(userData)}
            {userData?.spotHeader2 && userData?.spotFeaturedImage3 && (
              <div className="q-box mt-60">
                <h6 className="text-white fw-normal text-italic pb-4 bb1">
                  {userData?.spotHeader2}
                </h6>
                <p
                  className="body-large  fw-normal details text-white mt-60"
                  style={{
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                >
                  {userData?.spotSection2}
                </p>

                <div className="artwork-box">
                  <img
                    src={userData?.spotFeaturedImage3}
                    alt="thumbnail"
                    className="img-100"
                  />
                  <p className="body-large mt-3 pt-2 text-white fw-bold">
                    {userData?.spotFeaturedName3}
                  </p>
                  <label
                    onClick={() =>
                      window.open(userData.spotFeaturedLink3, "_blank")
                    }
                    htmlFor=""
                    className="small v-center pointer mt-2"
                  >
                    view artwork{" "}
                    <img src={more} alt="arrow" className="img-12" />
                  </label>{" "}
                </div>
              </div>
            )}

            {userData?.spotHeader3 && userData?.spotFeaturedImage3 && (
              <div className="q-box mt-60">
                <h6 className="text-white fw-normal text-italic pb-4 bb1 ">
                  {userData?.spotHeader3}
                </h6>
                <p
                  className="body-large  fw-normal details text-white mt-60"
                  style={{
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                  }}
                >
                  {userData?.spotSection1}
                </p>
              </div>
            )}

            <div className="explore-spotlight mt-5">
              <h6 className="text-white ">
                Explore other works from this artist
                <a
                  className="text-light-grey body-large ms-3 pointer "
                  onClick={handleSeeMore}
                >
                  SEE MORE{" "}
                  <img
                    src={more}
                    alt="arrow"
                    className="img-12 text-uppercase ms-2"
                  />
                </a>
              </h6>

              <div className="more pt-4 v-center justify-content-between">
                {spotLightUserArts.map((art, index) => (
                  <div className="artwork-box-sm" key={index}>
                    <img
                      src={art?.metadata?.data?.image}
                      alt="thumbnail"
                      className="thumbnail"
                    />
                    <p className="body-large mt-3 pt-2 text-white fw-bold">
                      {art?.metadata?.data?.artName}
                    </p>
                    <label
                      onClick={() => {
                        navigate(
                          `/artwork/${art?.metadata?.data?.selectedBlockchain}/${art?.metadata?.data?.selectedCollectionId}/${art?.data?.tokenId}`
                        );
                      }}
                      htmlFor=""
                      className="small v-center pointer mt-2"
                    >
                      view artwork{" "}
                      <img src={more} alt="arrow" className="img-12" />
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {userData?.spotUsername && (
              <div className="profile-box  follow-box br-30 v-center justify-content-between  ">
                <div className="left v-center">
                  {artistImage ? (
                    <img
                      src={artistImage}
                      alt="profile"
                      className="img-45"
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <img src={thumb} alt="user" className="img-45" />
                  )}
                  <h6 className="fw-bold text-white ms-2 text-normal">
                    @{userData?.spotUsername}
                  </h6>
                </div>
                <div className="right pe-1 v-center gap-3">
                  <Button
                    text={isFollowed ? "Following" : "Follow Artist"}
                    className="btn-prime text-white btn-primary bg-transparent"
                    width="126px"
                    height="36px"
                    onClick={() => {
                      if (isLoggedIn && address) {
                        handleFollowToggle();
                      } else {
                        setIsSignInModalOpen(true); // Setting to true instead of toggling for clarity
                      }
                    }}
                  />
                  <Button
                    text="View profile"
                    className="btn-prime text-black btn-primary bg-white"
                    width="126px"
                    height="36px"
                    onClick={() => {
                      if (isLoggedIn && address) {
                        navigate(`/profile/${userData?.spotAddress}`);
                      } else {
                        setIsSignInModalOpen(true); // Setting to true instead of toggling for clarity
                      }
                    }}
                  />
                </div>
              </div>
            )}

            <div className="artist-about-box  v-center justify-content-between">
              <div className="left">
                <a
                  href={`${userData?.spotWebsite}`}
                  target="_blank"
                  className="v-center"
                >
                  <img src={website} alt="site" className="img-30" />
                  <p className="text-white ms-2">{userData?.spotWebsite}</p>
                </a>
              </div>
              <div className="right">
                <div className="social-links">
                  {userData?.spotInstagram && (
                    <a href={userData?.spotInstagram} target="_blank">
                      <img
                        src={instagram}
                        alt="instagram"
                        className="invert1"
                      />
                    </a>
                  )}

                  {userData?.spotDiscord && (
                    <a href={userData?.spotDiscord} target="_blank">
                      <img src={discord} alt="discord" className="invert1" />
                    </a>
                  )}

                  {userData?.spotTwitter && (
                    <a href={userData?.spotTwitter} target="_blank">
                      <img src={twitter} alt="twitter" className="invert1" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="spotlight-page-content-disabled   d-flex align-items-center">
            <p className="text-white ">SpotLight Disabled</p>
          </div>
        )}
      </div>
      {/* <FooterV2 twitter={userData?.spotTwitter} /> */}
      <Footer twitter={userData?.spotTwitter} />
    </div>
  );
};

export default Spotlight;
