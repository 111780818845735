import { ContractFactory, ethers } from "ethers";
import { toast } from "react-toastify";
import Web3 from "web3";
import config from "../config";
// import mintContractABI from "../abis/erc721.json";
// import mintContractByteCode from "../abis/mint_contract_bytecode.json";
// import mintContractABI from "../abis/NewERC721ABI.json";
// import mintContractByteCode from "../abis/Newbytecode.json";
import mintContractABI from "../abis/SafeMint/v2/abi.json";
import mintContractByteCode from "../abis/SafeMint/v2/bytecode.json";
import { handleAdminNotifications } from "../firebase/firebase";
const { marketplace, erc20 } = config;
console.log(marketplace);
console.log(erc20);



const Marketplace_coston_contractAddress =
marketplace.coston;
const Marketplace_coston2_contractAddress =
marketplace.coston2;

const coston_Token = erc20.coston;
const coston2_Token =erc20.coston2;

export default async function CreateContract(signer,collectionName,symbolName,selectedNetwork) {

  console.log(selectedNetwork, "selected network");
  let marketPlaceAddress;

  if(selectedNetwork==="Coston"){
    marketPlaceAddress = Marketplace_coston_contractAddress
  }else{
    marketPlaceAddress=Marketplace_coston2_contractAddress;
  }

  console.log(marketPlaceAddress, "market place address");

  return new Promise(async (resolve, reject) => {
    try {
      console.log("SIGNER", signer);
      const userAddress = signer.account.address;

      console.log("User Address", userAddress); // User's address

      const web3 = new Web3(window.ethereum);

      const contract = new web3.eth.Contract(mintContractABI);

      contract.options.data = mintContractByteCode[0].bytecode;

      const constructorArgs = [userAddress,collectionName,symbolName,selectedNetwork,marketPlaceAddress]; // Provide your constructor arguments here

      const deployTx = contract.deploy({
        arguments: constructorArgs,
      });

      const gasLimit = await deployTx.estimateGas();
      const gasPrice = await web3.eth.getGasPrice();
      const gasLimitWithBuffer = Math.round(gasLimit * 1.5); // You can adjust the buffer as needed

      console.log("Deplot Tx", deployTx);

      const deployedContract = await deployTx
        .send({
          from: userAddress,
          account: userAddress,
          gas: gasLimit + 25000,
        })
        .once("transactionHash", async (txhash) => {
          console.log(`Mining deployment transaction ...`);
          const explorerUrl = "https://coston2-explorer.flare.network";
          console.log(`${explorerUrl}/tx/${txhash}`);
          for (let index = 0; index > -1; index++) {
            var receipt = await web3.eth.getTransactionReceipt(txhash);
            if (receipt != null) {
              if (receipt.status == true) {
                resolve({
                  success: true,
                  contractAddress: receipt.contractAddress,
                  hash: txhash,
                });
                break;
              } else {
                reject({ success: false });
                break;
              }
            }
          }
        })
        .catch(async(err) => {
          console.log(err, "errror of smart contra")
          if (err.code ) { // 4001 is the standard code for "user rejected transaction"
            // Notify admin about the transaction error
            await handleAdminNotifications(
              'transactionError',
              `Error: Smart Contract failed to execute, ${err.message}`,
              false
            );
          }
          reject({ success: false });
        });
      //  The contract is now deployed on chain!
      console.log(`Contract deployed at ${deployedContract}`);
      console.log(`Contract deployed at ${deployedContract.options.address}`);
      console.log(
        `Add DEMO_CONTRACT to the.env file to store the contract address: ${deployedContract.options.address}`
      );
    } catch (err) {
      console.log(err);
      reject({ success: false });
      // reject({ success: false })
    }
  });
}

