const environment = process.env.REACT_APP_VERCEL_ENV;

console.log(process.env.REACT_APP_BACKEND_URL_PROD);

console.log( process.env.REACT_APP_BACKEND_URL_STAGING);
const Backend_url = environment === 'production' 
  ? process.env.REACT_APP_BACKEND_URL_PROD 
  : process.env.REACT_APP_BACKEND_URL_STAGING;

  console.log(Backend_url);
export default Backend_url;
