import React from "react";

const DiscriptionCollection = (props) => {
  return (
    <>
      <div
        style={{
          maxWidth: "767px",
        }}
        className="mt-5 pt-3 pb-5"
      >
        <p className="mb-3 text-medium-grey text-break ">{props.description}</p>
      </div>
    </>
  );
};

export default DiscriptionCollection;
