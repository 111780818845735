import React, { useState, useEffect, useContext } from "react";

import filter from "../../assets/icon/filter.svg";
import grid from "../../assets/icon/display-grid-active.svg";
import row from "../../assets/icon/display-row-active.svg";
import artwork from "../../assets/icon/display-artwork-active.svg";
import sortby from "../../assets/icon/sort-by.svg";
import close from "../../assets/icon/close.svg";
import tick from "../../assets/icon/tick-large-black.svg";
import LayoutGrid from "../profile/layoutGrid";
import LayoutExploreGrid from "./layoutExploreGrid";
import AsideFilterExplore from "./asideFilterExplore";
import { Offcanvas } from "react-bootstrap";
import marketplaceContractABI from "../../abis/Marketplace/v3/abi.json";
import mintContractABI from "../../abis/SafeMint/abi.json";
import Web3 from "web3";
import CardSkelton from "../shared/cardSkelton";
import { useFeeData } from "wagmi";
import { ClearAllContext } from "./ClearAllContext";
import mintABI from "../../abis/SafeMint/abi.json";
import { useTagContext } from "./TagContext";
import config from "../../config";
import Backend_url from "../../herokuUrlConfig";
import UserCollectedArtwork from "../profile/userCollectedArtwork";
import {
  getArtLastPriceFromFirebase,
  getCollections,
  getUserData,
  getAllUsers
} from "../../firebase/firebase";
import { useAccount } from "wagmi";
import { lastIndexOf, update } from "lodash";
import { NFTContext } from "../context/appContext";
import { useLocation } from "react-router-dom";
import UserCollectedRow from "../profile/userCollectedRow";
import axios from "axios";
import _ from "lodash";
import { fetchNFTMetadata } from "../../Services/nft";
import Footer from "../footer";
const ExploreArt = ({ tagName, fromSearch, art }) => {
  // console.log(tagName, "tagName");
  // const { tagName } = useTagContext();
  const { clearAll, setClearAll } = useContext(ClearAllContext);
  const { isChange, search, clearTags, tagsData } = useContext(NFTContext);
  const location = useLocation();
  const [sgb, setSgb] = useState(0);
  const [flr, setFlr] = useState(0);
  // const [tagsData, setTagsData] = useState([]);

  const [allUsers, setAllUsers] = useState([]);



  const  getUsers=async()=>{
    let res = await getAllUsers();
    setAllUsers(res);
    console.log(res, "response of users");
  }

  useEffect(()=>{
    getUsers();
  },[])

  

  useEffect(() => {


    if (clearTags) {

      console.log(search, "value of search");
      console.log(location.state, "location datat");

      console.log(location?.state?.art, "tags data from exploare artttt");


      setLoading(true);

      setItemsNumber(tagsData?.length);

      if (search == false) {
        setItemsNumber(0);
        setLoading(true);
      }


    }
    else {
      setLoading(true);

      setItemsNumber(0);
    }

  }, [location, search, clearTags, tagsData])

  console.log(tagName, "tag Name");

  const [activeLayout, setactiveLayout] = useState("grid");
  const { address } = useAccount();
  const [mobileLayout, setMobileLayout] = useState(false);

  // multi filters

  const [showSideFilter, setShowSideFilter] = useState(false);
  const [Flag, setFlag] = useState(false);
  const [hide, setHide] = useState(false);
  


  const [test, setTest] = "this is testing state";

  //  handle mobile filters
  const handleClose = () => setMobileLayout(false);
  const handleShow = () => setMobileLayout(true);

  // layout tabs handles

  const handleActiveLayout = (image) => {
    setactiveLayout(image);
  };

  // let layout handle the icon of currently selected layout
  let layout;
  if (activeLayout == "grid") {
    layout = grid;
  } else if (activeLayout == "row") {
    layout = row;
  } else if (activeLayout == "artwork") {
    layout = artwork;
  }

  // ======================collection sorting filter
  const collectionSortFilter = [
    { value: "Lowest price", label: "Lowest price" },
    { value: "Highest price", label: "Highest price" },
    { value: "Newest", label: "Newest" },
    { value: "Oldest", label: "Oldest" },
  ];

  const [activeCollection, setActiveCollection] = useState("Recently Listed");

  const handleIPriceTick = (tick) => {
    setActiveCollection(tick);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > 992) {
        setShowSideFilter(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  // to show and hide collection multi filter
  const showMobileSideFilter = () => {
    setHide(!hide);
    if (windowWidth < 1000) {
      setShowSideFilter(true);
    } else {
      setFlag(!Flag);
    }
  };

  const closeMobileSideFilter = () => setShowSideFilter(false);

  // mobile sort filter
  const [collectionMobileFilter, setCollectionMobileFilter] = useState(false);
  const [activeCollectionFilter, setActiveCollectionFilter] =
    useState("Recently Listed");
  const hideMobileSortFilter = () => setCollectionMobileFilter(false);

  const showMobileSortFilter = () => {
    setCollectionMobileFilter(true);
  };

  const handleCollectionTick = (tick) => {
    setActiveCollectionFilter(tick);
    setCollectionMobileFilter(false);
  };
  const [loading, setLoading] = useState(true);
  const { marketplace, erc20 } = config;
  console.log(marketplace);
  console.log(erc20);



  const Marketplace_coston_contractAddress =
    marketplace.coston;
  const Marketplace_coston2_contractAddress =
    marketplace.coston2;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;
  const [nftDetails, setNftDetails] = useState([]);
  const [nftFilteredDetails, setNftFilteredDetails] = useState([]);

  // Initialize web3 with your Ethereum node URL
  // const web3 = new Web3(window.ethereum);
  const web3_coston = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
  const web3_coston2 = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);

  // get listed nfts from blockchain

  //  step no 1
  // get all collections
  const [collections, setCollections] = useState([]);
  const [listedNfts, setListedNfts] = useState([]);
  const [nfts, setNfts] = useState([]);
  const [nft, setNft] = useState(null);



  const getUserCollections = async () => {
    try {
      const usercollections = await getCollections();
      // Filter user collections where userAddress doesn't match with the provided address
      // const filteredCollections = usercollections.filter(
      //   (collection) => collection.address !== address
      // );
      console.log(usercollections, "userCollections");

      setCollections(usercollections);
    } catch (error) {
      console.error("Error fetching user collections:", error);
    }
  };

  const getAllListNFTData = async (selectedNetwork, web3) => {
    try {
      if (collections && web3 != null) {
        let MarketplaceAddress;
        if (selectedNetwork === "Coston") {
          MarketplaceAddress = Marketplace_coston_contractAddress;
        } else if (selectedNetwork === "Coston2") {
          MarketplaceAddress = Marketplace_coston2_contractAddress;
        }


        // alert("in listed");
        const contract = new web3.eth.Contract(
          marketplaceContractABI,
          MarketplaceAddress
        );
        try {
          const data = await contract.methods.getAllListedNfts().call();

          console.log(data, "listed data in explore art");
          setListedNfts(data);
          return data;
        }
        catch (error) {
          console.log(error, "error");
        }

      } else {
        setListedNfts([]);
      }
    }
    catch (error) {
      console.log(error);
    }

    // const accounts = await web3.eth.getAccounts();

  };

  let key = process.env.REACT_APP_CRYPTO_KEY;

  const fetchSgbPrice = async () => {
    try {
      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/pricemulti?fsyms=SGB&tsyms=USD&api_key=${key}`
      );
      const data = response.data;
      console.log(data, "api data");
      if (data["SGB"] && data["SGB"].USD !== undefined) {
        const priceInUSD = data["SGB"].USD;
        console.log(priceInUSD);
        setSgb(priceInUSD.toFixed(2));
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };

  const fetchFlrPrice = async () => {
    try {
      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/pricemulti?fsyms=FLR&tsyms=USD&api_key=${key}`
      );
      const data = response.data;
      console.log(data, "api data");
      if (data["FLR"] && data["FLR"].USD !== undefined) {
        const priceInUSD = data["FLR"].USD;
        console.log(priceInUSD);
        setFlr(priceInUSD.toFixed(2));
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log("error");
    }
  };


  useEffect(() => {
    console.log(flr, "flr");
  }, [flr]);
  useEffect(() => {
    // Throttle the functions to limit API calls to once per minute
    const throttledFetchSgbPrice = _.throttle(fetchSgbPrice, 60000);
    const throttledFetchFlrPrice = _.throttle(fetchFlrPrice, 60000);

    // Call the functions immediately
    throttledFetchSgbPrice();
    throttledFetchFlrPrice();

    // Set intervals to call the functions every minute
    const intervalSgb = setInterval(throttledFetchSgbPrice, 60000);
    const intervalFlr = setInterval(throttledFetchFlrPrice, 60000);

    // Clear intervals on component unmount
    return () => {
      clearInterval(intervalSgb);
      clearInterval(intervalFlr);
    };
  }, []);

  const [owner, setOwner] = useState(null);

  const gets = async (contractAddress, Address, tokenId) => {
    console.log(tokenId, "token Id");
    console.log(contractAddress, Address, "addresses in owner");

    let web3 = new Web3(window.ethereum);

    if (Address) {
      try {
        if (web3 !== null) {
          const contract = new web3.eth.Contract(
            mintContractABI,
            contractAddress
          );
          console.log(contract, "contrqact ");

          const result = await contract.methods
            .ownerOf(tokenId)
            .call({ from: Address });
          // alert("here");

          console.log(result, "result");
          return result;
        }
      } catch (error) {
        console.error(error);
      }
    }

  };

  const getNftMetadata = async (tokens_uri, network) => {
    try {
      console.log(tokens_uri, "token uri");
      setNfts([]);
      const nftMetadata = [];



      if (!clearTags) {
        await Promise.all(
          tokens_uri.map(async (token) => {
            const uri = token.uri; // Extract URI from the current token
            console.log(uri, "uri");

            try {
              const json = await fetchNFTMetadata(uri)

              console.log(json, "response of url ");
             

              const existingIndex = nftMetadata.findIndex((item) => item.uri === uri);
              if (existingIndex === -1 && json) {
                nftMetadata.push({
                  data: token,
                  metadata: {
                    uri: uri,
                    data: json.data,
                  },
                });
              }
            } catch (error) {
              console.log(`Failed to fetch metadata from ${uri}:`, error);
            }
          })
        );
      }


      // Skip processing nftMetadata if tagsData is present and not empty
      if (tagsData?.length > 0 && search) {
        await Promise.all(
          tagsData.map(async (e) => {
            console.log(e?.data.uri, "meta data in art id");
            let lastPrice = await getArtLastPriceFromFirebase(e.data.uri);
            console.log(lastPrice);
            if (lastPrice !== null) {
              e.metadata.data.lastPrice = lastPrice;
              console.log(`Updated element with last price: `, e);
            } else {
              console.log(`No last price found for artId`);
            }
          })
        );
        setItemsNumber(tagsData?.length);
        console.log(tagsData, "nft Meta data");
        setLoading(false);
        setNft(tagsData);
        setNftData(tagsData);

      } else {
        await Promise.all(
          nftMetadata.map(async (e) => {
            console.log(e?.data.uri, "meta data in art id");
            let lastPrice = await getArtLastPriceFromFirebase(e.data.uri);
            console.log(lastPrice);
            if (lastPrice !== null) {
              e.metadata.data.lastPrice = lastPrice;
              console.log(`Updated element with last price: `, e);
            } else {
              console.log(`No last price found for artId`);
            }
          })
        );

        setItemsNumber(nftMetadata?.length);
        console.log(nftMetadata, "nft Meta data");
        setLoading(false);
        setNft(nftMetadata);
        setNftData(nftMetadata);
      }
    } catch (error) {
      console.log(error);
    }
  };



  // step no 2
  //get nfts of user based on address and map and set listed data to nfts

  const fetchDataForContract = async (contractAddress, selectedNetwork, id) => {

    try {
      console.log(id, "id");

      let web;
      if (selectedNetwork == "Coston2") {
        web = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
      } else {
        web = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
      }
      let nftListDetails = [];
      let nftArray = [];

      if (web) {
        const contract = new web.eth.Contract(mintABI, contractAddress);
        const nftData = await contract.methods.getTokenIdsByCollection(id).call();
        console.log(nftData, "nftData");
        // if (nftData.length > 0) {
        //   nftArray = [...nftArray];
        // }
        console.log(nftArray, "nft Array");

        console.log(selectedNetwork);
        console.log(web);
        let listedNfts = await getAllListNFTData(selectedNetwork, web);
        console.log(listedNfts, "listed nfts");
        nftData?.map((item) => {
          let isSaleListed = false;
          let isOfferListed = false;
          let saleCountIndex = 0;  // Initialize saleCountIndex
          let offerCountIndex = 0; // Initialize offerCountIndex

          // Check for sale listings
          listedNfts[0]?.forEach((nft) => {
            if (nft?.uriData === item?.uri) {
              isSaleListed = true;
              console.log(nft?.listCount);
              saleCountIndex = nft?.listCount; // Get listCount from sale listing
              nftListDetails.push({
                ...nft,
                ...item,
                isListed: true,
                isSaleListed: true,
                saleCountIndex, // Add saleCountIndex to the object
              });
            }
          });

          // Check for offer listings
          listedNfts[1]?.forEach((nft) => {
            if (nft?.uriData === item?.uri) {
              isOfferListed = true;
              offerCountIndex = nft?.listCount; // Get listCount from offer listing
              const existingIndex = nftListDetails.findIndex(
                (nftDetail) => nftDetail.uri === item.uri
              );

              if (existingIndex !== -1) {
                nftListDetails[existingIndex] = {
                  ...nftListDetails[existingIndex],
                  isOfferListed: true,
                  offerCountIndex, // Add offerCountIndex to the object
                };
              } else {
                nftListDetails.push({
                  ...nft,
                  ...item,
                  isListed: true,
                  isOfferListed: true,
                  offerCountIndex, // Add offerCountIndex to the object
                });
              }
            }
          });

          // Ensure both flags and indices are set correctly for NFTs listed in both categories
          if (isSaleListed || isOfferListed) {
            const existingIndex = nftListDetails.findIndex(
              (nftDetail) => nftDetail.uri === item.uri
            );
            if (existingIndex !== -1) {
              nftListDetails[existingIndex] = {
                ...nftListDetails[existingIndex],
                isListed: true,
                isSaleListed: isSaleListed,
                isOfferListed: isOfferListed,
                saleCountIndex: isSaleListed
                  ? saleCountIndex
                  : nftListDetails[existingIndex].listCount,
                offerCountIndex: isOfferListed
                  ? offerCountIndex
                  : nftListDetails[existingIndex].listCount,
              };
            }
          }
        });


      }
      console.log(nftListDetails, "nft List detais");
      return nftListDetails;
    }
    catch (error) {
      console.log(error);
    }

  };

  // fet data for nfts collected by user (connected address)

  const fetchDataForAllContracts = async () => {
    try {
      let contractDataArray = [];

      for (const item of collections) {
        console.log(item, "item");
        let data = await fetchDataForContract(
          item?.data.contractAddress,
          item?.data.selectedNetwork,
          item?.documentId
        );
        if (data?.length > 0) {
          contractDataArray.push(data);
        }
      }
      console.log(contractDataArray, "contractDataArray");

      // Flatten the contractDataArray into a single array
      const flattenedTokens = contractDataArray.flat();
      console.log(flattenedTokens, "flat tokens");

      // Pass the flattened array to getNftMetadata
      getNftMetadata(flattenedTokens);

      // setLoading(false);
      console.log(collections?.length, "collection length");
      if (collections?.length === 0) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
    catch (error) {
      console.log(error);
    }

  };

  useEffect(() => {
    if (collections?.length > 0) {
      fetchDataForAllContracts();
    } else {
      setLoading(false);
    }
  }, [collections, tagsData, search, clearTags]);

  useEffect(() => {
    console.log(tagsData);

  }, [tagsData])
  useEffect(() => {
    console.log(listedNfts, "listedNfts");
  }, [listedNfts]);

  useEffect(() => {
    getUserCollections();
  }, [address, tagsData, search]);

  const [nftData, setNftData] = useState([]);
  const [offerNftData, setOfferNftData] = useState([]);
  const [itemsNumber, setItemsNumber] = useState(0);
  useEffect(() => {
    console.log(nft, "nft testing data");

    // Get the current time once
    const now = new Date();

    // Map through the nft array and calculate early access
    const updatedNftFilteredDetails = nft?.map((item) => {
      console.log(item, "item value in filtered data");

      // Check if the item is sale-listed and extract the list time
      const isSaleListed = item?.data?.isSaleListed;
      const listTime = item?.data?.listedData?.listTime;

      console.log(isSaleListed, "is sale listed");
      console.log(listTime, "list time");

      // Default early access to false if the item is not sale-listed
      if (isSaleListed) {


        // Convert listTime (Unix timestamp) to a Date object if available
        const createdTime = listTime ? new Date(listTime * 1000) : null;
        console.log(createdTime, "create time");

        // If createdTime is invalid, return early access as false
        if (!createdTime) {
          console.error("Invalid mintTime:", item);
          return { ...item, earlyAccess: false };
        }

        // Calculate the time difference
        const timeElapsed = now - createdTime;
        const hoursElapsed = Math.floor(timeElapsed / (1000 * 60 * 60)); // Convert milliseconds to hours

        console.log(hoursElapsed, "hours since NFT creation");

        // Determine if the NFT is still in the early access period (less than 24 hours)
        const earlyAccess = hoursElapsed < 24;

        console.log(earlyAccess, "early access");

        return {
          ...item,
          earlyAccess, // Add earlyAccess property
        };

      } else {
        return { ...item, earlyAccess: false };
      }

    });




    // filter for blacklisted users

    // get all users




    const filteredNfts = updatedNftFilteredDetails?.filter(nft => {

      console.log(nft,"nft data");
      // Find the user with the matching ID
      const owner = allUsers.find(user => user.id === nft.data.newOwner);

    
      // Check if the owner is found and is not blacklisted
      return owner && !owner.isBlackListed;
    });
    


    setItemsNumber(filteredNfts?.length);


    // Update the state with the new array
    setNftFilteredDetails(filteredNfts);

    // Debugging - delayed logging after state update
    console.log(filteredNfts, "Updated NFT filtered details");
  }, [nft]);

  const handleSelectedFilterChange = (newFilter) => {
    console.log(newFilter, "new filter");
    setLoading(true);

    let filteredData = [];

    if (nft?.length > 0) {
      console.log(nft, "nft");

      const now = Date.now();

      // Map over the NFT data and calculate early access based on mint time
      const updatedNftFilteredDetails = nft?.map((item) => {
        console.log(item, "item value in filtered data");

        // Check if the item is sale-listed
        const isSaleListed = item?.data?.isSaleListed;
        const mintTime = item?.data?.listedData?.listTime;;

        console.log(isSaleListed, "is sale listed");
        console.log(mintTime, "mint time");

        // Default early access to false if the item is not sale-listed
        if (!isSaleListed) {
          return { ...item, earlyAccess: false };
        }

        // Convert mintTime (Unix timestamp) to a Date object if available
        const createdTime = mintTime ? new Date(mintTime * 1000) : null;

        if (!createdTime) {
          console.error("Invalid mintTime:", item);
          return { ...item, earlyAccess: false }; // Default to no early access if mintTime is missing
        }

        const timeElapsed = now - createdTime; // Calculate the time difference
        const hoursElapsed = Math.floor(timeElapsed / (1000 * 60 * 60)); // Convert milliseconds to hours

        console.log(hoursElapsed, "hours since NFT creation");

        // Determine if the NFT is still in the early access period (less than 24 hours)
        const earlyAccess = hoursElapsed < 24;

        console.log(earlyAccess, "early access");

        return {
          ...item,
          earlyAccess, // Add earlyAccess property
        };
      });



      console.log(updatedNftFilteredDetails, "updatedNftFilteredDetails");
      // Define conversion rates for USD based on selected blockchain
      const conversionRates = {
        coston: sgb, // Assuming sgb is the variable holding the conversion rate
        coston2: flr, // Assuming flr is the variable holding the conversion rate
      };

      // Apply price filter function
      const applyPriceFilter = (item) => {
        let priceFilter = true;
        if (newFilter.selectPrice.min !== "" || newFilter.selectPrice.max !== "") {
          let priceInEth = parseFloat(item.data.listedData.price) / 1e18;

          // Adjust price based on selected priceCurrency
          if (newFilter.priceCurrency === "USD") {
            const blockchain = item.metadata.data.selectedBlockchain?.toLowerCase();
            const conversionRate = conversionRates[blockchain];
            priceInEth *= conversionRate;
            console.log(priceInEth, "price in there");
          } else if (
            newFilter.priceCurrency === "CFLR" &&
            item.metadata.data.selectedBlockchain?.toLowerCase() !== "coston"
          ) {
            priceFilter = false;
          } else if (
            newFilter.priceCurrency === "C2FLR" &&
            item.metadata.data.selectedBlockchain?.toLowerCase() !== "coston2"
          ) {
            priceFilter = false;
          }

          priceFilter =
            priceFilter &&
            (newFilter.selectPrice.min === "" || priceInEth >= parseFloat(newFilter.selectPrice.min)) &&
            (newFilter.selectPrice.max === "" || priceInEth <= parseFloat(newFilter.selectPrice.max));
        }

        return priceFilter;
      };

      // Filter based on buyNow status
      if (newFilter.selectStatus.buyNow) {
        const buyNowNfts = updatedNftFilteredDetails.filter((item) => item?.data.isSaleListed === true);

        buyNowNfts.forEach((item) => {
          const isCategorySelected = Object.values(newFilter.selectCategories).some((category) => category);
          const selectedCurrency = Object.values(newFilter.selectCurrency).some((currency) => currency);

          let categoriesFilter = true;
          let currencyFilter = true;

          if (isCategorySelected) {
            categoriesFilter = Object.keys(newFilter.selectCategories).every(
              (category) =>
                newFilter.selectCategories[category]
                  ? item.metadata.data.selectedTags.includes(category)
                  : true
            );
          }

          if (selectedCurrency) {
            currencyFilter =
              newFilter.selectCurrency.allChains ||
              (newFilter.selectCurrency.flr && item.metadata.data.selectedBlockchain?.toLowerCase() === "flare network") ||
              (newFilter.selectCurrency.sgb && item.metadata.data.selectedBlockchain?.toLowerCase() === "songbird network") ||
              (newFilter.selectCurrency.coston && item.metadata.data.selectedBlockchain?.toLowerCase() === "coston") ||
              (newFilter.selectCurrency.coston2 && item.metadata.data.selectedBlockchain?.toLowerCase() === "coston2");
          }

          if (categoriesFilter && currencyFilter && applyPriceFilter(item)) {
            filteredData.push(item);
          }
        });
      }



      console.log(filteredData, "fitlered data no111111111111");
      // Filter based on onOffer status
      if (newFilter.selectStatus.onOffer || newFilter.selectStatus.All) {
        const offerNftData = updatedNftFilteredDetails.filter((item) => item.data.isOfferListed === true);

        offerNftData.forEach((item) => {
          const isCategorySelected = Object.values(newFilter.selectCategories).some((category) => category);
          const selectedCurrency = Object.values(newFilter.selectCurrency).some((currency) => currency);

          let categoriesFilter = true;
          let currencyFilter = true;

          if (isCategorySelected) {
            categoriesFilter = Object.keys(newFilter.selectCategories).every(
              (category) =>
                newFilter.selectCategories[category]
                  ? item.metadata.data.selectedTags.includes(category)
                  : true
            );
          }

          if (selectedCurrency) {
            currencyFilter =
              newFilter.selectCurrency.allChains ||
              (newFilter.selectCurrency.flr && item.metadata.data.selectedBlockchain?.toLowerCase() === "flare network") ||
              (newFilter.selectCurrency.sgb && item.metadata.data.selectedBlockchain?.toLowerCase() === "songbird network") ||
              (newFilter.selectCurrency.coston && item.metadata.data.selectedBlockchain?.toLowerCase() === "coston") ||
              (newFilter.selectCurrency.coston2 && item.metadata.data.selectedBlockchain?.toLowerCase() === "coston2");
          }

          if (categoriesFilter && currencyFilter && applyPriceFilter(item)) {
            filteredData.push(item);
          }
        });
      }
      console.log(filteredData, "fitlered data no22222222222");

      if (newFilter.selectStatus.earlyAccess) {
        console.log(updatedNftFilteredDetails, "UPDATED FILTERS");
        const earlyAccessFilter = updatedNftFilteredDetails.filter((item) => item?.earlyAccess === true);

        console.log(earlyAccessFilter, "early access ffdfffilter");

        earlyAccessFilter.forEach((item) => {
          const isCategorySelected = Object.values(newFilter.selectCategories).some((category) => category);
          const selectedCurrency = Object.values(newFilter.selectCurrency).some((currency) => currency);

          let categoriesFilter = true;
          let currencyFilter = true;

          if (isCategorySelected) {
            categoriesFilter = Object.keys(newFilter.selectCategories).every(
              (category) =>
                newFilter.selectCategories[category]
                  ? item.metadata.data.selectedTags.includes(category)
                  : true
            );
          }

          if (selectedCurrency) {
            currencyFilter =
              newFilter.selectCurrency.allChains ||
              (newFilter.selectCurrency.flr && item.metadata.data.selectedBlockchain?.toLowerCase() === "flare network") ||
              (newFilter.selectCurrency.sgb && item.metadata.data.selectedBlockchain?.toLowerCase() === "songbird network") ||
              (newFilter.selectCurrency.coston && item.metadata.data.selectedBlockchain?.toLowerCase() === "coston") ||
              (newFilter.selectCurrency.coston2 && item.metadata.data.selectedBlockchain?.toLowerCase() === "coston2");
          }

          if (categoriesFilter && currencyFilter && applyPriceFilter(item)) {


            console.log(item, "itwem in early access members");
            filteredData.push(item);
          }
        });
      }


      console.log(filteredData, "filtered data");
      setItemsNumber(filteredData.length);
      setNftFilteredDetails(filteredData);
    }

    setLoading(false);
  };



  useEffect(() => {
    console.log(nft, "Highest price nft data");
    if (nft) {

      if (activeCollection === "Highest price") {
        const nftsWithPrice = [...nft].filter(a => a?.data?.listedData?.price !== undefined);
        const nftsWithoutPrice = [...nft].filter(a => a?.data?.listedData?.price === undefined);

        const sortByHighestPrice = nftsWithPrice.sort((a, b) => {
          const aPrice = a?.data?.listedData?.price;
          const bPrice = b?.data?.listedData?.price;

          console.log(bPrice);
          console.log(aPrice);

          return bPrice - aPrice;
        });

        // Concatenate sorted NFTs with price and NFTs without price
        const sortedNftList = [...sortByHighestPrice, ...nftsWithoutPrice];

        setNftFilteredDetails(sortedNftList);
      }



      // Sorting by lowest price
      else if (activeCollection === "Lowest price") {
        const nftsWithPrice = [...nft].filter(a => a?.data?.listedData?.price !== undefined);
        const nftsWithoutPrice = [...nft].filter(a => a?.data?.listedData?.price === undefined);

        const sortByLowestPrice = nftsWithPrice.sort((a, b) => {
          const aPrice = a?.data?.listedData?.price;
          const bPrice = b?.data?.listedData?.price;

          return aPrice - bPrice;
        });

        // Concatenate sorted NFTs with price and NFTs without price
        const sortedNftList = [...sortByLowestPrice, ...nftsWithoutPrice];

        console.log(sortedNftList, "sort by lowest");
        setNftFilteredDetails(sortedNftList);
      }

      // Getting the first 5 recently listed items
      else if (activeCollection === "Newest") {
        const recentlyAdded = [...nft].sort((a, b) => {
          return b?.data
            ? b?.data?.mintTime -
            (a?.data && a?.data.mintTime ? a?.data.mintTime : 0)
            : 0;
        });
        // .slice(0, 5);
        console.log("Newest", recentlyAdded);
        setNftFilteredDetails(recentlyAdded);
      } else if (activeCollection === "Oldest") {
        const oldestAdded = [...nft].sort((a, b) => {
          return a?.data
            ? a?.data?.mintTime -
            (b?.data && b?.data.mintTime ? b?.data.mintTime : 0)
            : 0;
        });
        // .slice(0, 5);

        console.log("Oldest", oldestAdded);
        setNftFilteredDetails(oldestAdded);
      }
    }
    // setLoading(false);
  }, [nft, activeCollection]);
  useEffect(() => {
    console.log(showSideFilter, "showSideFilter");
  }, [showSideFilter]);

  const clearFilter = () => {
    setClearAll(true);
  };
  const handlClearAll = () => {
    setClearAll(false);
  };
  const filterNFTDataByTagName = (data, tag) => {
    return data.filter((item) => item.meta?.data?.selectedTags.includes(tag));
  };
  useEffect(() => {
    if (!tagName) return;
    console.log(tagName);
    console.log(nftFilteredDetails);
    console.log(nftFilteredDetails.length > 0);
    if (nftFilteredDetails.length > 0) {
      console.log(nftFilteredDetails, "nftFilteredDetails");
      console.log(tagName);

      const filteredNftData = nftFilteredDetails?.nftData.filter((item) =>
        item.meta?.data?.selectedTags.includes(tagName)
      );
      const filteredOfferNftData = nftFilteredDetails?.offerNftData.filter(
        (item) => item.meta?.data?.selectedTags.includes(tagName)
      );

      setNftFilteredDetails({
        nftData: filteredNftData,
        offerNftData: filteredOfferNftData,
      });
    }
  }, [tagName, nftFilteredDetails]);

  // Separate state for holding the filtered results

  useEffect(() => {
    console.log(nftFilteredDetails, "nft filtered details");
  }, [nftFilteredDetails]);
  useEffect(() => {
    console.log(loading, isChange, "loading");
  }, [loading, isChange]);

  useEffect(() => {

    console.log(search);
    console.log(tagsData);


  }, [search, tagsData])

  useEffect(() => {

    console.log(activeLayout, "active layout")
  }, [activeLayout])

  return (
    <div>
      {search && tagsData?.length == 0 && location?.state?.tag ? <>
        <div className="no-content mt-5 pt-5">
          <p className="body-large">

            No Artwork Found for <span>{location?.state?.tag}</span>
          </p>

        </div>
      </> : (
        <div>
          <div className="collection-filter">
            <div className="left">
              <div
                className="show-filter cursor-pointer "
                onClick={showMobileSideFilter}
              >
                <span>
                  <img src={filter} alt="filter" id="filter" />
                </span>
                <label
                  htmlFor="filter"
                  className="medium text-black cursor-pointer ms-1 hide-on-mobile"
                >
                  {hide ? "Hide Filters" : "Show Filters"}
                </label>
              </div>
              <div className="t-items">
                {hide && isChange ? (
                  <label onClick={clearFilter} className="medium ms-4">
                    Clear All
                  </label>
                ) : (
                  <label className="medium ms-4">{itemsNumber} Items</label>
                )}
              </div>
            </div>



            <div className="right">
              {/* Sorting filter dropdown desktop*/}
              <div className="collection-grid hide-on-mobile">
                <img
                  src={grid}
                  alt="grid"
                  className={
                    activeLayout === "grid" ? "active opacity-100 " : ""
                  }
                  onClick={() => handleActiveLayout("grid")}
                />
                <div className="divider"></div>
                <img
                  src={row}
                  alt="grid"
                  className={
                    activeLayout === "row" ? "active opacity-100" : ""
                  }
                  onClick={() => handleActiveLayout("row")}
                />
                <div className="divider"></div>
                <img
                  src={artwork}
                  alt="grid"
                  className={
                    activeLayout === "artwork" ? "active opacity-100" : ""
                  }
                  onClick={() => handleActiveLayout("artwork")}
                />
              </div>

              {/* layout tabs for  mobile menu */}
              <div className="collection-grid-sm">
                <span
                  className="hide-on-desktop d-flex align-items-center me-1"
                  onClick={handleShow}
                >
                  <img
                    src={layout}
                    alt="layout"
                    className={activeLayout === "grid" ? "active " : ""}
                  />
                </span>
              </div>
              <div className="recent-collection filter dropdown hide-on-mobile ">
                <p
                  className="body-medium dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="hide-on-mobile">
                    {
                      collectionSortFilter.find(
                        (option) => option.value === activeCollection
                      )?.label
                    }
                  </span>
                  <span>
                    <img src={sortby} alt="sortby" />
                  </span>
                </p>
                <ul className="dropdown-menu">
                  {collectionSortFilter.map((option) => {
                    return (
                      <li
                        className="dropdown-item"
                        key={option.value}
                        onClick={() => handleIPriceTick(option.value)}
                      >
                        <img
                          src={tick}
                          alt="tick"
                          className={`${activeCollection === option.value
                            ? "active opacity-100"
                            : "opacity-0"
                            }`}
                        />
                        {option.label}
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* Sorting filter dropdown Mobile*/}
              <div className="recent-collection filter hide-on-desktop ">



                <p className="body-medium " onClick={showMobileSortFilter}>
                  <span className="hide-on-mobile">
                    {
                      collectionSortFilter.find(
                        (option) => option.value === activeCollection
                      )?.label
                    }
                  </span>
                  <span>
                    <img src={sortby} alt="sortby" />
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* <LayoutExploreGrid
            flag={Flag}
            listedNft={nftFilteredDetails}
            onSelectedFilterChange={handleSelectedFilterChange}
            loading={loading}
            setLoading={setLoading}
            setItemsNumber={setItemsNumber}
            tagName={tagName}
          />
 */}



          {activeLayout == "grid" ? (
            <LayoutExploreGrid
              flag={Flag}
              listedNft={nftFilteredDetails}
              onSelectedFilterChange={handleSelectedFilterChange}
              loading={loading}
              setLoading={setLoading}
              setItemsNumber={setItemsNumber}
              tagName={tagName}
            />

          ) : activeLayout == "row" ? (
            <UserCollectedRow
              id={null}
              flag={Flag}
              listedNft={nftFilteredDetails}
              onSelectedFilterChange={handleSelectedFilterChange}
              owner={owner}
              address={address}
              loading={loading}
              type="explore"
            />
          ) : activeLayout == "artwork" ? (
            <UserCollectedArtwork
              id={null}
              flag={Flag}
              listedNft={nftFilteredDetails}
              onSelectedFilterChange={handleSelectedFilterChange}
              owner={owner}
              address={address}
              loading={loading}
            />
          ) : (
            <></>
          )}
        </div>
      )}

      <Offcanvas
        show={showSideFilter}
        onHide={closeMobileSideFilter}
        placement="bottom"
        className="sub-menu-offcanvas collection-multi-filter aside-filter-offcanvas"
      >
        <div className="more-menu-sm price-more-menu ">
          <div className="menu-head">
            <label htmlFor="">
              {nftFilteredDetails?.length ? nftFilteredDetails?.length : "0"}{" "}
              Items
            </label>
            <label className="text-black multi-filter-head">Filters</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={closeMobileSideFilter}
              />
            </div>
          </div>

          {/* <AsideFilterExplore /> */}
          <AsideFilterExplore
            onSelectedFilterChange={handleSelectedFilterChange}
            clear={clearAll}
          />
        </div>
      </Offcanvas>

      {/* Collection Sorting Filter mobile menu */}
      <Offcanvas
        show={collectionMobileFilter}
        onHide={hideMobileSortFilter}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu">
          <div className="menu-head">
            <label className="text-black">sort by</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={hideMobileSortFilter}
              />
            </div>
          </div>

          <div className="share">
            {collectionSortFilter.map((option) => (
              <label
                key={option.value}
                className={`no-text-transform h-64 text-black ${activeCollectionFilter === option.value
                  ? "fw-bold"
                  : "fw-normal"
                  }`}
                onClick={() => handleCollectionTick(option.value)}
              >
                {option.label}
                <img
                  src={tick}
                  alt="tick"
                  className={`${activeCollectionFilter === option.value
                    ? "active opacity-100"
                    : "opacity-0"
                    }`}
                />
              </label>
            ))}
          </div>
        </div>
      </Offcanvas>
    </div>
  );
};

export default ExploreArt;