import React, { useEffect, useState, useContext } from "react";
import Header from "../header";
import Input from "../inputs";
import Button from "../button";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ReCAPTCHA from "react-google-recaptcha";
import { redirectDocument, useAsyncError, useNavigate } from "react-router-dom";
import ImageUpload from "../imageUpload";
import CustomCheckBox from "../shared/customTags";
import { toast } from "react-toastify";
import {
  handleNotifications,
  getCollectionByAddress,
  setUsername,
} from "../../firebase/firebase";
import imgg from "../../assets/images/collectionPlaceholder.png";
import {
  getCollections,
  updateArtDataToFirebase,
  getCollectionStats,
  saveCollectionStats,
  saveArtworkHistory,
  handleCollectionHistory,
  saveArtDataToFirebase,
  getUserData,
  handleAdminNotifications,
  getFollowersByAddress,
} from "../../firebase/firebase";
import { useAccount, useWalletClient } from "wagmi";
import { useSwitchNetwork, useNetwork } from "wagmi";
import Web3 from "web3";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import placeholder from "../../assets/images/profile-1.svg";
import { NFTContext } from "../context/appContext";

import { useLocation } from "react-router-dom";
import Loader from "../shared/Loader";
import { Skeleton } from "antd";
import { Image } from "antd";
import Backend_url from "../../herokuUrlConfig";
import InputBox from "../shared/inputBox";
import Textarea from "../shared/textarea";
import { fetchNFTMetadata,uploadNFT } from "../../Services/nft";
const MintArt = () => {
  const { address } = useAccount();
  const navigate = useNavigate();
  const location = useLocation();
  const web3 = new Web3(window.ethereum);
  let mintContract;
  const { artModal, udpateArtModal } = useContext(NFTContext);

  // useStates
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [createArtWork, setCreateArtWork] = useState(false);
  const [ArtworkData, setArtworkData] = useState({});
  const [artworkDocumentId, setArtworkDocumentId] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedCollectionId, setSelectedCollectionId] = useState(null);
  const [selectedContract, setSelectedContract] = useState(null);
  const [NFTDataUrl, setNFTDataUrl] = useState(null);
  const [collections, setCollections] = useState([]);
  const [createFinishSuccess, setCreateFinishSuccess] = useState(false);
  const [artLink, setArtLink] = useState(null);

  const [selectedBlockchain, setSelectedBlockchain] =
    useState("Select Blockchain");
  const [username, setUsername] = useState(null);
  const [flag, setFlag] = useState(true);

  const [user, setUser] = useState([]);
  useEffect(() => {
    let getUser = async () => {
      let res = await getUserData(address);
      setUser(res);
      setUsername(res?.userName);
    };
    getUser();
  }, [address]);

  const [globalErrors, setGlobalErrors] = useState({
    selectedBlockchain: false,
    selectedCollectionId: false,
    selectedCollection: false,
  });
  const { chains, pendingChainId, switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();
  const [artId, setArtId] = useState(null);

  const [followers, setFollowers] = useState([]);

  const getFollowers = async () => {
    let res = await getFollowersByAddress(address);
    console.log(res, "response of followers");
    setFollowers(res);
  };
  useEffect(() => {
    getFollowers();
  }, [address]);

  const { state } = location;
  useEffect(() => {
    if (!location) {
      navigate("/");
    }

    if (!flag) {
      navigate("/");
    }
    console.log(state, "state location");
  }, [location, flag]);

  const handleCreateFinishSuccess = () => {
    // Reset all relevant state variables
    setCreateFinishSuccess(false);
    setCreateArtWork(false);
    setArtworkData({});
    setArtworkDocumentId(null);
    setSelectedCollection(null);
    setSelectedCollectionId(null);
    setSelectedContract(null);
    setNFTDataUrl(null);
    setCollections([]);
    setSelectedBlockchain("Select Blockchain");
    setUsername(null);
    setFlag(false);
    setUser([]);
    setArtId(null);
    setGlobalErrors({
      selectedBlockchain: false,
      selectedCollectionId: false,
      selectedCollection: false,
    });
    state = null;

    navigate(`/explore-collections/${selectedCollectionId}`);
  };

  // Access the `name` value from the state object

  const { name } = state;
  const { imageUrl } = state.art;
  const { videoUrl } = state.art;
  const { previewImageUrl } = state.art;
  console.log(videoUrl, "video urllllllllll");

  useEffect(() => {
    console.log(name);
    setSelectedCollection(name);
    console.log("location.state", location?.state);
    if (location?.state) {
      // setArtId(location.state.doc);
      setArtworkData(location.state?.art);
      // setArtworkDocumentId(location.state?.doc);
      handleCreateArtwork();
    }
    console.log("ArtworkData", ArtworkData);
  }, [location?.state]);

  useEffect(() => {
    if (address == undefined || address == null || address == "") {
      toast.error("Please connect wallet");
    } else {
      console.log("address: ", address);
      mintContract = new web3.eth.Contract(mintContractABI, selectedContract);
    }
  }, [selectedContract]);

  useEffect(() => {
    if (createArtWork) {
      window.scrollTo(0, 0);
    }
  }, [createArtWork]);

  useEffect(() => {
    getUserCollections();
  }, [location, name]);

  const handleItemSelected = (item, documentId, Address) => {
    setSelectedCollection(item);
    setSelectedCollectionId(documentId);
    setSelectedContract(Address);
  };

  const handleCaptchaModal = () => setShowCaptcha(!showCaptcha);

  const verifyHuman = async () => {
    navigate("/artwork");
  };

  const handleCreateArtwork = () => {
    setCreateArtWork(true);
  };

  const getUserCollections = async () => {
    console.log(name, "name");
    try {
      const userCollections = await getCollections();
      console.log(userCollections, "userCollections");

      let cName = [];
      // Filter the collections where the collectionName matches the name variable

      for (let a of userCollections) {
        console.log(name?.name || name);
        console.log(a);

        if (a.data.name === name?.name || name) {
          // alert(a.data.name);
          console.log(a.data.name, "testing namee");
          cName.push(a);
        }
      }

      // Update state with the filtered collections
      setCollections(cName);
    } catch (error) {
      console.error("Error fetching collections:", error);
    }
  };

  const uploadArtDataToFirebase = async (
    url,
    transactionHash,
    nftData,
    account
  ) => {
    console.log(url, "artwork url");
    console.log(ArtworkData, "artwork data");

    if (ArtworkData) {
      let artData = { ...ArtworkData };
      artData.url = url;

      try {
        const response = await saveArtDataToFirebase(
          username,
          artData.name,
          artData.description,
          artData.traits,
          artData.selectedTags,
          artData.artistFee,
          artData.artistAddress,
          true, // isMinted
          artData.image,
          artData.previewImg,
          artData.videoUrl,
          artData.url
        );

        if (response.success) {
          setArtId(response.documentId);
          setArtworkDocumentId(response.documentId);
          console.log("Artwork data uploaded to Firebase successfully");

          // Update collection statistics
          handlecollectionStats();

          // Save artwork history
          try {
            await saveArtworkHistoryWithRetries(
              transactionHash,
              address,
              "Mint",
              username,
              response.documentId,
              3
            );

            // If NFT exists, handle collection history
            if (nftData && selectedCollectionId) {
              const data = {
                action: "Mint",
                user: username,
                artworkUri: nftData,
                from: account,
                to: selectedContract,
                tokenId: null,
                uri: url,
                transactionHash: transactionHash,
              };

              await handleCollectionHistory(selectedCollectionId, data);
            }

            // Get the artwork URL from the contract
            const artLink = await getUserNftsFromContract(artData.url);
            console.log(artLink, "art link in response");

            // Handle notifications

            await handleNotifications(
              address,
              "minted",
              "",
              ArtworkData.name,
              transactionHash,
              selectedBlockchain,
              artLink // Pass the art link in the notification
            );

            followers?.data?.map(async (item) => {
              await handleNotifications(
                address,
                "followActivity",
                "",
                ArtworkData.name,
                transactionHash,
                selectedBlockchain,
                artLink,
                item
              );
            });
            // Navigate to the art link
            navigate(artLink, { state: { loc: "mint" } });
          } catch (error) {
            console.error("Error saving artwork history:", error);
          }
        } else {
          console.error("Failed to upload artwork data to Firebase");
        }
      } catch (error) {
        console.error("Error uploading artwork data to Firebase:", error);
      }
    } else {
      console.error("No art data found in state");
    }
  };

  const createArt = async () => {
    setIsDisabled(true);
    setLoading(true);
    let errors = {
      selectedCollectionId: false,
      selectedCollection: false,
    };

    if (!selectedCollectionId) {
      errors.selectedCollectionId = true;
    }
    if (!selectedCollection) {
      errors.selectedCollection = true;
    }

    setGlobalErrors(errors);

    if (Object.values(errors).every((error) => !error)) {
      const nftData = {
        artistFee: ArtworkData.artistFee,
        artistAddress: ArtworkData.artistAddress,
        artistName: username,
        artName: ArtworkData.name,
        image: imageUrl,
        previewImg: previewImageUrl || null,
        videoUrl: videoUrl || null,
        description: ArtworkData.description,
        traits: ArtworkData.traits,
        selectedTags: ArtworkData.selectedTags,
        mintedBy: username,
        selectedCollection,
        selectedCollectionId,
        selectedBlockchain,
        mintedAt: new Date(),
      };

      await uploadNFtToS3(nftData);

      setLoading(false);
    }
  };
  // Upload NFT to S3
  const [uri, setUri] = useState(null);
  const [nft, setNft] = useState([]);

  const uploadNFtToS3 = async (NFTdata) => {
    try {
      console.log("NFTdata: ", NFTdata);

      const data = await uploadNFT(NFTdata);
      console.log(data,"response");
      
      if (data) {
        const nftUrl = data.nftUrl;

        console.log("NFT URL:", nftUrl);
        console.log(data.nftData, "nft data");

        // Proceed with minting the NFT
        await mintNFT(nftUrl, NFTdata);
      } else {
        console.error("Error uploading NFT Data Object:", data.error);
      }
    } catch (error) {
      console.error("Error uploading NFT Data Object:", error);
    }
  };

  useEffect(() => {
    console.log(nft, "nft data...........");
  }, [nft]);

  const getNftMetadata = async (tokens_uri) => {
    const json = await fetchNFTMetadata(tokens_uri);
   
    console.log(json, "json");
    setUri(json?.data);
    return {
      data: json?.data,
    };
  };

  const getMeta = async (nftUrl) => {
    // alert("called");
    console.log(nftUrl, "nft Url");

    const rs = await getNftMetadata(nftUrl);
    if (rs) {
      console.log(rs, "response data from meta data");
      setUri(rs);
      return rs;
    }
  };
  useEffect(() => {
    console.log(NFTDataUrl, "nft data url");
    if (NFTDataUrl) {
      getMeta(NFTDataUrl);
    }
  }, [NFTDataUrl]);
  useEffect(() => {
    console.log(uri, "uri");
  }, [uri]);
  // switch network

  const handleSwitchChain = async () => {
    const switchTo = chains.find((c) => c.name === selectedBlockchain);
    if (switchTo) {
      switchNetwork?.(switchTo.id);
    }
    if (selectedCollection == null) {
      alert("please select colletion");
    }
  };

  // mint nft function
  const [tokenId, setTokenId] = useState(null);

  const getUserNftsFromContract = async (nftUrl) => {
    console.log(nftUrl, "nft url");

    let res = await getCollectionByAddress(ArtworkData.artistAddress);
    console.log(res, "response");
    console.log(selectedCollectionId);

    let collectionDetails = res.filter(
      (e) => e.documentId === selectedCollectionId
    );
    console.log(collectionDetails[0]?.contractAddress);

    if (collectionDetails[0]?.contractAddress) {
      let web3_1;
      if (selectedBlockchain === "Coston") {
        web3_1 = new Web3(process.env.REACT_APP_COSTON_RPC_URL);
      } else if (selectedBlockchain === "Coston2") {
        web3_1 = new Web3(process.env.REACT_APP_COSTON2_RPC_URL);
      }
      console.log(web3_1);
      console.log(selectedBlockchain);

      try {
        if (web3_1 !== null) {
          const contract = new web3_1.eth.Contract(
            mintContractABI,
            collectionDetails[0]?.contractAddress
          );
          const contractOwner = await contract.methods.owner().call();

          return new Promise((resolve, reject) => {
            contract.methods
              .getTokenId(collectionDetails[0]?.address)
              .call({ from: contractOwner }, (error, result) => {
                if (error) {
                  console.error(error);
                  reject(error);
                } else {
                  if (result?.length > 0) {
                    for (let a of result) {
                      if (a.uri === nftUrl) {
                        console.log(a.tokenId);
                        udpateArtModal();
                        toast.success("Artwork Created Successfully!");
                        resolve(
                          `/artwork/${selectedBlockchain}/${selectedCollectionId}/${a.tokenId}`
                        );
                        return;
                      }
                    }
                  } else {
                    reject("Error: No result found");
                  }
                }
              });
          });
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  };

  const mintNFT = async (nftUrl, nftData) => {
    console.log(selectedContract, "selected contract");
    console.log(nftUrl, "nft Url");

    if (!selectedContract || !nftUrl) {
      console.error("Contract or NFTDataUrl is not set");
      return;
    }

    try {
      const accounts = await web3.eth.getAccounts();
      const contract = new web3.eth.Contract(mintContractABI, selectedContract);

      // Retrieve owner of the contract
      const owner = await contract.methods.owner().call();

      // Create transaction to mint NFT
      const transaction = contract.methods.safeMint(
        nftUrl,
        ArtworkData.artistAddress,
        ArtworkData.artistFee,
        selectedCollectionId
      );

      // Estimate gas cost
      const estimatedGasCost = await transaction.estimateGas({
        from: accounts[0],
      });
      const gasLimit = Math.ceil(estimatedGasCost * 1.2); // Add a 20% buffer to the estimated gas cost

      // Send transaction
      const result = await transaction.send({
        from: accounts[0],
        gas: gasLimit,
      });

      console.log(result, "result");

      const transactionHash = result?.transactionHash;

      // Continue with uploading NFT data to Firebase
      await uploadArtDataToFirebase(
        nftUrl,
        transactionHash,
        nftData,
        accounts[0]
      );
    } catch (error) {
      setCreateFinishSuccess(false);
      setIsDisabled(false);
      toast.error("Transaction Failed!");
      console.error("Error minting NFT:", error);

      let notificationType = "transactionError";
      let notificationMessage = `Error in minting artwork: ${error.message}`;

      // Check if the error is due to network congestion or an RPC URL issue
      if (error.message && error.message.includes("network congestion")) {
        notificationType = "networkCongestion";
        notificationMessage =
          "Attention: Blockchain network congestion detected. Please try again later.";
      } else if (error.message && error.message.includes("RPC URL")) {
        notificationType = "rpcError";
        notificationMessage =
          "Attention: RPC URL error detected. Please check the network and try again.";
      } else if (error.code === 4001) {
        notificationType = "test";
        notificationMessage = "Attention: rejected by user.";
        return;
      }

      await handleAdminNotifications(
        notificationType,
        notificationMessage,
        false
      );
    }
  };

  // Function to save artwork history with retries
  const saveArtworkHistoryWithRetries = async (
    transactionHash,
    address,
    action,
    username,
    artId,
    retries
  ) => {
    for (let attempt = 1; attempt <= retries; attempt++) {
      try {
        let res = await saveArtworkHistory(
          transactionHash,
          address,
          action,
          username,
          artId,
          null,
          null
        );
        console.log(res);
        return;
      } catch (error) {
        console.error(
          `Error saving artwork history (Attempt ${attempt}):`,
          error
        );
        if (attempt === retries) {
          throw error; // Rethrow after final attempt
        }
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait before retrying
      }
    }
  };

  const MintNft = async (nftUrl, data) => {
    setLoading(true);

    try {
      await mintNFT(nftUrl, data);

      setLoading(false);
      // handleNotifications(username, `Minted by ${username}`, "minted", "");
    } catch (error) {
      console.error("Error during minting process:", error);
      setLoading(false);
    }
  };

  // store collection stats to firebase

  const handlecollectionStats = async () => {
    const accounts = await web3.eth.getAccounts();
    const collectionStats = await getCollectionStats(selectedCollectionId);
    if (collectionStats) {
      let creatorEarning = collectionStats?.creatorEarning;
      if (ArtworkData.artistFee > creatorEarning) {
        creatorEarning = ArtworkData.artistFee;
      }
      let owners = collectionStats?.owners;
      if (owners.length > 0) {
        if (!owners.includes(accounts[0])) {
          owners.push(accounts[0]);
        }
      } else {
        owners.push(ArtworkData.artistAddress);
      }

      const data = {
        collectionId: selectedCollectionId,
        artworkCount: collectionStats?.artworkCount + 1 || 0 + 1,
        createdAt: collectionStats?.createdAt,
        creatorEarning: creatorEarning,
        volume: collectionStats?.volume,
        SGBvolume: collectionStats?.SGBvolume,
        FLRvolume: collectionStats?.FLRvolume,
        USDvolume: collectionStats?.USDvolume,
        floorPrice: collectionStats?.floorPrice,
        listedCount: collectionStats?.listedCount,
        saleCount: collectionStats?.saleCount,
        owners: owners,
      };
      saveCollectionStats(data);
    }
  };

  useEffect(() => {
    console.log(collections);
    if (collections.length >= 1 && name) {
      collections.map((item) => {
        console.log(name);
        if (item.data.name === name) {
          console.log(item?.data.name);
          setSelectedCollection(item.data.name);
          handleItemSelected(item.data.name, item.documentId);
          setSelectedBlockchain(item.data.selectedNetwork);
          setSelectedContract(item.data.contractAddress);
        }
      });
    }
  }, [collections, name]);

  useEffect(() => {
    console.log(videoUrl, "video url");
  }, [videoUrl]);
  return (
    <div>
      <section className="create-artwork pt-0">
        <Header head="Confirm" />
        {createArtWork ? (
          <div className="create-artwork-content  ">
            <h4 className="medium-head">Confirm Details</h4>
            <>
              <div className="upload-file mt-40">
                {imageUrl ? (
                  <>
                    {/* <Image
                      width="100%"
                      src={imageUrl}
                      alt="art"
                      style={{
                        width: "100%",
                        maxHeight: "300px",
                        objectFit: "contain",
                      }}
                    /> */}

                    <img
                      src={imageUrl}
                      alt="art"
                      className="img-fluid mt-3"
                      style={{
                        maxHeight: "300px",
                        objectFit: "contain",
                      }}
                    />
                  </>
                ) : (
                  <img
                    src={previewImageUrl}
                    alt="art"
                    className="img-fluid mt-3"
                    style={{
                      maxHeight: "300px",
                      objectFit: "contain",
                    }}
                  />
                )}
              </div>

              {/* art name */}
              {/* <div className="custom-inputBox  mt-30">
                <label className="text-black ">Artwork Name</label>

                <div className="input-box mt-3 br-40  custom-class">
                  <input
                    id="inputId"
                    name="name"
                    type="text"
                    value={ArtworkData.name}
                    placeholder="Artwork Name"
                    disabled={true}
                    className="custom-class inputtype1"
                  />
                </div>
              </div> */}
              <div className="mt-30">
                <InputBox
                  label="Artwork Name"
                  name="name"
                  type="text"
                  value={ArtworkData.name}
                  placeholder="Artwork Name"
                  disabled={true}
                  boxClassName="disabled"
                />
              </div>

              {/* Description */}

              {ArtworkData.description && (
                <>
                  <div className="art-name mt-40 d-flex flex-column">
                    <label className="text-black ">Description</label>
                  </div>
                  <Textarea
                    id="inputId"
                    name="description"
                    placeholder="Description"
                    className="disabled"
                    value={ArtworkData.description}
                    disabled
                  />
                </>
              )}

              {/* Artist fee */}
              {/* <div className="art-name mt-40 d-flex flex-column">
                <label className="text-black mb-3">
                  Artist fee
                  <span className="body-medium no-text-transform fw-500 text-medium-grey ms-2">
                    Percentage
                  </span>
                </label>
                <div className="input-box br-20 ">
                  <Input
                    type="Number"
                    placeholder="Amount"
                    className="inputtype1"
                    value={ArtworkData.artistFee}
                  />
                </div>
              </div> */}

              {/* Traits */}
              {ArtworkData?.traits.length > 0 && (
                <>
                  <div className="artwork-traits">
                    <p className="body-large fw-bold mt-30 d-flex justify-content-between">
                      <span>Traits</span>
                    </p>
                    <p className="body-small text-medium-grey mt-3 ">
                      A distinguishing quality or characteristic.
                    </p>
                    {ArtworkData?.traits.map((trait, index) => (
                      <div
                        key={index}
                        className="add-traits d-flex flex-column"
                      >
                        <div className="d-flex  add-traits-head gap-5">
                          <div className="left">
                            <label className="text-black ">Type</label>
                          </div>
                          <div className=" left">
                            <label className="text-black ">name</label>
                          </div>
                        </div>

                        {/* static */}
                        <div className="d-flex mt-3 gap-5">
                          <div className="left">
                            <div className="input-box br-20 ">
                              <Input
                                type="text"
                                placeholder="Type"
                                value={trait.type}
                                className="inputtype1"
                                disabled={true}
                              />
                            </div>
                          </div>
                          <div className="name ">
                            <div className="input-box br-20 ">
                              <Input
                                type="text"
                                placeholder="Type"
                                value={trait?.name}
                                disabled={true}
                                className="inputtype1"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {ArtworkData?.selectedTags?.length > 0 && (
                <div className="artwork-tags">
                  <p className="body-large fw-bold mt-40 d-flex justify-content-between">
                    <span>Tags: </span>
                  </p>
                  <div className="mt-22">
                    <CustomCheckBox
                      values={ArtworkData?.selectedTags}
                      disabled={true}
                      cursor="hover-none"
                    />
                  </div>
                </div>
              )}
              {/* Collection */}
              {/* <div className="art-collections">
                <p className="body-large fw-bold mt-40 d-flex justify-content-between">
                  <span>Collection</span>
                  <span className="fw-normal text-underline pointer">
                    Manage collections
                  </span>
                </p>
                <p className="body-small text-medium-grey mt-3 ">
                  This is the collection where your artwork will appear.
                </p>
                {/* <Dropdown className="select-collection">
                  <Dropdown.Toggle id="dropdown-basic">
                    {selectedCollection ? selectedCollection : "Collections"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="w-100">
                    {collections?.map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => {
                          setSelectedCollection(item.data.name);
                          handleItemSelected(item.data.name, item.documentId);
                          setSelectedBlockchain(item.data.selectedNetwork);
                          setSelectedContract(item.data.contractAddress);
                        }}
                      >
                        {item.data.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>  

                <div
                  className="create-new-collection v-center mt-2 pointer w-100"
                  style={{ maxWidth: "100%" }}
                >
                  <div className="left">
                    <img src={imgg} alt="" className="img-100" />
                  </div>
                  <div>
                    <label className="large text-black no-text-transform fw-bold pointer">
                      {selectedCollection}
                    </label>
                    <br />
                    <label className="small no-text-transform fw-500 pointer">
                      {location?.state?.name?.symbol}
                    </label>
                  </div>
                  <span className="small"></span>
                </div>

                {globalErrors.selectedCollection && (
                  <p className="warning">Selected collection is missing</p>
                )}
              </div> */}

              <div className="divider"></div>

              {/* Create artwork button */}
              {chain.name === selectedBlockchain ? (
                <Button
                  text="Create Artwork"
                  className="btn-prime btn-primary br-30 font-18"
                  height="50px"
                  width="100%"
                  loading={loading}
                  onClick={createArt}
                  disabled={isDisabled}
                />
              ) : (
                <Button
                  text="Switch to selected blockchain"
                  className="btn-prime btn-primary br-30 font-18"
                  height="50px"
                  width="100%"
                  onClick={handleSwitchChain}
                />
              )}
            </>
          </div>
        ) : (
          <></>
        )}

        <Modal
          show={showCaptcha}
          onHide={handleCaptchaModal}
          className="sign-modal "
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <label htmlFor="" className="medium">
                ALMOST DONE
              </label>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="captcha-content v-center flex-column pb-5">
              <p className="body-medium text-center mb-5">
                Before we create this item, please confirm that you are indeed a
                human by ticking the box below.
              </p>
              <ReCAPTCHA
                sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                onChange={verifyHuman}
              />
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={createFinishSuccess}
          onHide={handleCreateFinishSuccess}
          className="sign-modal "
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <label htmlFor="" className="medium">
                finished
              </label>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="newArt-done v-center flex-column">
              <h5 className="italic-head mb-3">All Done</h5>

              <p className="body-medium">
                You listed <span className="fw-500">{ArtworkData?.name}</span>{" "}
                from <span className="fw-500">{selectedCollection}</span>
              </p>

              {imageUrl && imageUrl ? (
                <img src={imageUrl || previewImageUrl} alt="img" />
              ) : (
                <img src={previewImageUrl} alt="img" />
              )}

              <Button
                text="View Artwork"
                className="btn-prime btn-primary"
                width="131px"
                height="36px"
                onClick={() => getUserNftsFromContract(uri)}
              />
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </div>
  );
};

export default MintArt;
