import config from "../config";
import axios from 'axios';
import emailjs from '@emailjs/browser';

import {verifyEmail, sendVerificationEmail} from "../firebase/firebase";


console.log(config.pinata.apiKey, config.pinata.apiSecret, config.pinata.gateway);

// Pin File (Image/Video) to IPFS
const pinFileToIPFS = async (file) => {
    console.log(file,"file of pin file to ipfs");
    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
    try {
        const formData = new FormData();
        
        // Append the file directly since it's already in the correct format
        formData.append('file', file);


        console.log(formData, "formData");
        const response = await axios.post(url, formData, {
            headers: {
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                pinata_api_key: config.pinata.apiKey,
                pinata_secret_api_key: config.pinata.apiSecret
            }
        });
        console.log(response,"response");

        return {
            success: true,
            pinataUrl: `${config.pinata.gateway}/ipfs/${response.data.IpfsHash}`
        };
    } catch (error) {
        console.error('Pinata error:', error);
        return {
            success: false,
            message: error.message
        };
    }
};

// Upload Image to IPFS
const uploadImage = async (imageFile) => {
    try {
        console.log(imageFile,"imageFile");
        const pinataResponse = await pinFileToIPFS(imageFile);

        if (!pinataResponse.success) {
            throw new Error(pinataResponse.message || 'Failed to pin image to IPFS');
        }

        return {
            success: true,
            imageUrl: pinataResponse.pinataUrl
        };
    } catch (error) {
        console.error('Error in uploadImage:', error);
        return {
            success: false,
            error: error.message
        };
    }
};

// Upload Video to IPFS
const uploadVideo = async (videoFile) => {
    try {
        const pinataResponse = await pinFileToIPFS(videoFile);

        if (!pinataResponse.success) {
            throw new Error(pinataResponse.message || 'Failed to pin video to IPFS');
        }

        return {
            success: true,
            videoUrl: pinataResponse.pinataUrl
        };
    } catch (error) {
        console.error('Error in uploadVideo:', error);
        return {
            success: false,
            error: error.message
        };
    }
};

// Pin JSON to IPFS
const pinJSONToIPFS = async (JSONBody) => {
    const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
    try {
        const response = await axios.post(url, JSONBody, {
            headers: {
                'Content-Type': 'application/json',
                pinata_api_key: config.pinata.apiKey,
                pinata_secret_api_key: config.pinata.apiSecret,
            },
        });

        return {
            success: true,
            pinataUrl: `${config.pinata.gateway}/ipfs/${response.data.IpfsHash}`,
        };
    } catch (error) {
        console.error('Pinata error:', error);
        return {
            success: false,
            message: error.message,
        };
    }
};

// Upload NFT Metadata to IPFS and then to your backend
const uploadNFT = async (nftData) => {
    try {
        const {
            artistFee,
            artistAddress,
            artistName,
            artName,
            image,
            previewImg,
            videoUrl,
            description,
            traits,
            selectedTags,
            mintedBy,
            selectedCollection,
            selectedCollectionId,
            selectedBlockchain,
            mintedAt,
        } = nftData;

        const metadata = {
            artistFee,
            artistAddress,
            artistName,
            artName,
            image,
            previewImg,
            videoUrl,
            description,
            traits,
            selectedTags,
            mintedBy,
            selectedCollection,
            selectedCollectionId,
            selectedBlockchain,
            mintedAt,
        };

        const pinataResponse = await pinJSONToIPFS(metadata);

        if (!pinataResponse.success) {
            throw new Error(pinataResponse.message || 'Failed to pin metadata to IPFS');
        }

        console.log(pinataResponse,"pinataResponse");
        return {
            success: true,
            nftUrl: pinataResponse.pinataUrl,
            nftData: metadata,
        };

    } catch (error) {
        console.error('Error in uploadNFTMetadata:', error);
        return {
            success: false,
            error: error.message,
        };
    }
};

// Fetch NFT Metadata from IPFS
const fetchNFTMetadata = async (uri) => {
    try {
        // If the URI is an IPFS hash, make sure we're using the gateway
        const url = uri.startsWith('ipfs://')
            ? `${config.pinata.gateway}/ipfs/${uri.replace('ipfs://', '')}`
            : uri;

        const response = await axios.get(url);

        if (!response.data) {
            throw new Error('No data received from IPFS');
        }

        return {
            success: true,
            data: response.data
        };
    } catch (error) {
        console.error('Error fetching NFT metadata:', error);
        return {
            success: false,
            error: error.message
        };
    }
};

// Send verification email using EmailJS and update Firebase
const sendVerificationEmailWithEmailJS = async (userId, email, templateParams) => {
  try {
    // Initialize EmailJS with your public key
    emailjs.init("ZJ9G8J1S_-mE3ZV2V"); // Replace with your actual public key

    // Send email using EmailJS
    const response = await emailjs.send(
      'service_4cirf09', // Your EmailJS service ID
      'template_ed8kioj', // Your EmailJS template ID
      templateParams
      // Public key is already initialized above
    );

    if (response.status === 200) {
      // Update Firebase after successful email send
      const firebaseResponse = await sendVerificationEmail(userId, email);
      
      if (firebaseResponse.success) {
        return {
          success: true,
          message: "Verification email sent and database updated successfully"
        };
      } else {
        throw new Error(firebaseResponse.error || "Failed to update database");
      }
    } else {
      throw new Error("Failed to send verification email");
    }

  } catch (error) {
    console.error("Error in sendVerificationEmailWithEmailJS:", error);
    return {
      success: false,
      error: error.message
    };
  }
};






export { uploadNFT, uploadImage, uploadVideo, pinFileToIPFS, pinJSONToIPFS, fetchNFTMetadata, sendVerificationEmailWithEmailJS };