import React from "react";
import globe from "../assets/icon/website.svg";
import discordIcon from "../assets/icon/discord.svg";
import twitter from "../assets/icon/twitter.svg";
import Footer from "../components/footer";

function HelpCernter() {
  return (
    <>
      <div className="catalyst-content">
        <div className="mx-auto " style={{ maxWidth: "700px" }}>
          <label className="medium">Help Center</label>

          <h4 className="medium-head">
            Unlock Early Access to Exclusive NFT Drops
          </h4>

          <p className="body-large mt-36 pb-5 bb">
            Our NFT marketplace offers tiered early access to new releases,
            allowing members to purchase new NFTs before they are available to
            the general public. Access is tiered according to your membership
            level. Here's how it works and how you can gain access:
          </p>

          <h6 className="my-5">How Early Access Works:</h6>

          <div className="d-flex flex-column gap-48 bb pb-5">
            <p className="body-large">
              Tiered early access is specifically for primary sales - also known
              as mint sales. Primary sales occur when whitelisted artists first
              mint and sell their artwork on our platform. This differs from
              secondary sales, which are any sales that happen after the initial
              mint sale. There are five stages of a mint sale:
            </p>
            <p className="body-large">
              <b>Gold Members:</b> First 6 hours after a primary sale release.
            </p>
            <p className="body-large">
              <b>Silver Members: </b>Access begins 6 hours after the primary
              sale release.
            </p>
            <p className="body-large">
              <b> Bronze Members:</b> Access begins 12 hours after the primary
              sale release.
            </p>
            <p className="body-large">
              <b>Standard Members:</b> Access begins 18 hours after the primary
              sale release.
            </p>
            <p className="body-large">
              <b> General Public:</b> Access opens 24 hours after the primary
              sale release.
            </p>
          </div>

          <div className="d-flex flex-column gap-48 mt-5 pb-5 bb">
            <h6 className="">Membership Qualification:</h6>

            <p className="body-large">
              Qualification requires ownership of specific NFTs. These are as
              follows:
            </p>
            <p className="body-large">
              <b>Gold Members </b>
              (one of each NFT):
            </p>

            <div>
              <p className="body-large">
                <a href="#" className="text-underline">
                  Fat Cats{" "}
                </a>
                NFT
              </p>
              <p className="body-large">
                <a href="#" className="text-underline">
                  Fat Leopards{" "}
                </a>
                NFT
              </p>
              <p className="body-large">
                <a href="#" className="text-underline">
                  Fat Tigers{" "}
                </a>
                NFT
              </p>
              <p className="body-large">
                <a href="#" className="text-underline">
                  Fat Kittens{" "}
                </a>
                NFT
              </p>
            </div>

            <p className="body-large">
              <b>Silver Members </b>
              (one of each NFT):
            </p>
            <div>
              <p className="body-large">
                <a href="#" className="text-underline">
                  Fat Leopards{" "}
                </a>
                NFT
              </p>
              <p className="body-large">
                <a href="#" className="text-underline">
                  Fat Tigers{" "}
                </a>
                NFT
              </p>
              <p className="body-large">
                <a href="#" className="text-underline">
                  Fat Kittens{" "}
                </a>
                NFT
              </p>
            </div>

            <p className="body-large">
              <b>Bronze Members </b>
              (one of each NFT):
            </p>
            <div>
              <p className="body-large">
                <a href="#" className="text-underline">
                  Fat Tigers{" "}
                </a>
                NFT
              </p>
              <p className="body-large">
                <a href="#" className="text-underline">
                  Fat Kittens{" "}
                </a>
                NFT
              </p>
            </div>

            <p className="body-large">
              <b>Standard Members </b>
              (one of each NFT):
            </p>
            <div>
              <p className="body-large">
                <a href="#" className="text-underline">
                  Fat Kittens{" "}
                </a>
                NFT
              </p>
            </div>
          </div>

          <div className="pt-5">
            <p className="body-large">
              Members also enjoy lower marketplace fees which you can read about
              <a href="#"> here</a>, alongside earlier access to new releases
              depending on their tier.
            </p>
            <p className="body-large">
              To join a membership tier and unlock early access to primary sales
              and discounts on fees, you can purchase the necessary NFTs via the
              links above.
            </p>

            <div className="links bb bt d-flex justify-content-between mt-60">
              <div>
                <p className="body-large">
                  <img src={globe} alt="" />
                  Still need help? Contact us on Discord and Twitter.
                </p>
              </div>
              <div>
                <a href="https://discord.gg/zAQ9RAh7db" target="_blank">
                  <img src={discordIcon} alt="discord" />
                </a>{" "}
                <a href="https://twitter.com/thefatcatsNFT" target="_blank">
                  <img src={twitter} alt="discord" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default HelpCernter;
