import React, { useEffect, useState } from "react";
import Header from "../header";
import ImageUpload from "../imageUpload";
import Button from "../button";
import { toast } from "react-toastify";
import Input from "../inputs";
import Textarea from "../shared/textarea";
import { useLocation } from "react-router-dom";
import ImageUploadCollection from "../imageUploadCollection";
import {
  getCollectionDetailsFirebase,
  saveEditCollectionDetails,
} from "../../firebase/firebase";

const EditCollection = () => {
  const [image, setImage] = useState("");
  const [description, setDescription] = useState("");
  const [collectionUrl, setCollectionUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileType, setFileType] = useState("image");

  const location = useLocation();

  // save updated data to firebase

  const saveData = async () => {
    setLoading(true);

    const imageChanged = image !== collectionDetails.image;
    const descriptionChanged = description !== collectionDetails.description;
    const collectionUrlChanged =
      collectionUrl !== collectionDetails.collectionUrl;

    if (!imageChanged && !descriptionChanged && !collectionUrlChanged) {
      toast.info("Edit collection to save");
      setLoading(false);
      return;
    }

    const descriptionData = description || "";
    const collectionUrlData = collectionUrl || "";
    const imageData = image || "";

    try {
      await saveEditCollectionDetails(
        imageData,
        descriptionData,
        collectionUrlData,
        location.pathname.split("/")[2]
      );

      await getCollectionDetails();

      setLoading(false);
    } catch (error) {
      console.error("Error while saving data:", error);
      setLoading(false);
      // Optionally, display an error message to the user
      toast.error("Failed to save data. Please try again.");
    }
  };

  // get collection details

  const [collectionDetails, setCollectionDetails] = useState("");
  useEffect(() => {
    setImage(collectionDetails.image);
    setDescription(collectionDetails.description);
    setCollectionUrl(collectionDetails.collectionUrl);
  }, [collectionDetails]);
  const getCollectionDetails = async () => {
    const result = await getCollectionDetailsFirebase(
      location.pathname.split("/")[2]
    );
    setCollectionDetails(result);
  };

  useEffect(() => {
    getCollectionDetails();
  }, []);

  useEffect(() => {
    console.log(collectionDetails, "collectionDetails");
  }, [collectionDetails]);

  return (
    <div>
      <section className="create-artwork">
        <Header head="Create" />
        <div className="create-artwork-content  ">
          <h4 className="medium-head">Edit collection</h4>

          {/* Collection logo */}
          <div className="preview-file upload-file mt-40">
            <p className="body-large fw-bold v-center justify-content-between">
              Collection Cover Image
            </p>
            <p className="body-small mt-3 text-medium-grey">
              {/* JPC, PNC, GIF, SVG. Max size: 5MB */}
              700 x 700 JPG, PNG, or GIF. Max size: 1 MB
            </p>
            <ImageUploadCollection
              onChange={(e) => {
                console.log(e.target.files[0]);
                setImage(e.target.files[0] || collectionDetails?.image);
              }}
              name="logo"
              value={image || collectionDetails?.image}
              setImage={setImage}
              locate="Collection"
            />
          </div>

          <div className="art-name mt-40 d-flex flex-column">
            <label className="text-black v-center justify-content-between">
              Collection Description
              <span className="body-medium no-text-transform fw-500 text-medium-grey ms-2">
                optional
              </span>
            </label>

            <Textarea
              placeholder="Add a description..."
              maxLength={500}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              value={description}
            />
          </div>

          <Button
            text="Save changes"
            className="btn-prime btn-primary br-30 font-18 mt-40"
            height="50px"
            width="100%"
            loading={loading}
            onClick={() => saveData()}
          />
        </div>
      </section>
    </div>
  );
};

export default EditCollection;
