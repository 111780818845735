import React from "react";
import globe from "../assets/icon/website.svg";
import discordIcon from "../assets/icon/discord.svg";
import twitter from "../assets/icon/twitter.svg";
import Footer from "../components/footer";

function Privacy() {
  return (
    <>
      <div className="catalyst-content privacy-page">
        <div className="mx-auto px-3" style={{ maxWidth: "700px" }}>
          {/* <label className="medium">Help Center</label> */}
          <h4 className="medium-head">Welcome</h4>
          <p className="body-large mt-36 pb-5  ">
            Welcome to The Catalyst, owned and operated by Fat Cats Club Ltd.
            ("we," "us," "our"). This Privacy Policy explains how we collect,
            use, share, and secure your personal information when you interact
            with our platform via our website [www.the-catalyst.xyz] (the
            “Platform”). Whether you are creating, buying, transferring, or
            trading unique digital assets on our Marketplace (the
            "Marketplace"), this Privacy Policy forms a crucial part of the
            Terms of Service you agree to when using our services.
            <br />
            <br />
            By accepting our Terms of Service, you acknowledge and consent to
            the processing of your personal data as described herein. This
            policy extends to all visitors, users, collectors, and artists
            interacting with our platform and outlines the handling of your
            personal information. It covers aspects such as collection, use,
            storage, sharing, retention, and processing of personal data.
            “Personal Information” in this context refers to any data related to
            an identifiable individual.
            <br />
            <br />
            We periodically update this policy and recommend that you review it
            regularly. Updates will be clearly posted on this page. In the event
            of significant changes, we will provide a prominent notice. Should
            you find any part of this policy disagreeable, please refrain from
            using our platform, products, or services.
          </p>
          <div>
            <h4 className="medium-head mt-4 mb-4">
              1. Collection of Personal Information
            </h4>
            <div className="d-flex flex-column   pb-5">
              <p className="body-large mb-3">
                <b>Personal information you provide:</b>
              </p>
              <p className="body-large">
                When you register on our Platform, we collect essential
                information for setting up and securing your account which
                includes your:
              </p>
              <ul className="my-3">
                <li> Digital wallet address</li>
                <li> Username</li>
              </ul>
              <p className="body-large">
                You may also provide additional information to enhance your
                prole:
              </p>

              <ul className="my-3">
                <li>Name or pseudonym </li>
                <li> Email address</li>
                <li> Prole picture or avatar </li>
                <li> Location </li>
                <li> Social media handles and website links </li>
              </ul>

              <div>
                <p className="body-large mb-3">
                  <b>Information Collected Automatically:</b>
                </p>
                <p className="body-large  ">
                  As you navigate through our Platform, we automatically collect
                  information such as:
                </p>
                <ul className="my-3">
                  <li>
                    Your IP address and user-agent details from your browser{" "}
                  </li>
                  <li>
                    {" "}
                    Usage data detailing your interactions with our services{" "}
                  </li>
                  <li>
                    {" "}
                    User activities including likes, views, shares, transfers,
                    minting, buying and selling{" "}
                  </li>
                </ul>
              </div>

              <div>
                <p className="body-large mb-3">
                  <b>Information Collected from Blockchain Transactions:</b>
                </p>
                <p className="body-large  ">
                  We may gather information about you from publicly available
                  blockchain networks, such as the Songbird blockchain and the
                  Flare blockchain.Transactions made on our platform are
                  permanently stored on the blockchain and include details of:
                </p>
                <ul className="my-3">
                  <li>NFT sales</li>
                  <li>NFT purchases</li>
                  <li>NFT transfers</li>
                </ul>
              </div>

              <div>
                <p className="body-large mb-3">
                  <b>Permanent Record on the Blockchain</b>
                </p>
                <p className="body-large  ">
                  Transactions on our Platform—encompassing sales, purchases,
                  bids, or transfers of NFTs or digital assets—are permanently
                  stored on the blockchain, specically on the Songbird Network
                  or the Flare Network, based on transaction details. For user
                  convenience and accessibility, we may also maintain copies of
                  this blockchain data on our own servers.
                  <br />
                  <br />
                  It's crucial to understand that once data is registered on the
                  blockchain, it is beyond the capacity of The Fat Cats Club to
                  modify or remove. To inspect the details of any blockchain
                  transaction, you can use the blockchain explorers at{" "}
                  <a
                    href="https://songbird-explorer.flare.network/"
                    target="_blank"
                  >
                    https://songbird-explorer.flare.network/
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://flare-explorer.flare.network/"
                    target="_blank"
                  >
                    https://flare-explorer.flare.network/
                  </a>
                  .
                </p>
              </div>

              <div>
                <p className="body-large mb-3 mt-3">
                  <b>Information from Social Media and Public Sources:</b>
                </p>

                <ul className="my-3">
                  <li>
                    We may collect personal information from other sources
                    including external social media accounts, articles, websites
                    and public databases.
                  </li>
                  <li>
                    Third-party sources such as wallet services and blockchain
                    explorers, as well as collaborations with third-party
                    partners, also provide us with data.
                  </li>
                </ul>
              </div>

              <div>
                <p className="body-large mb-3">
                  <b>Use of Cookies and Tracking Technologies:</b>
                </p>
                <p className="body-large">
                  To enhance site navigation and analyze site usage, we employ
                  cookies and other tracking technologies. Details on the use of
                  these technologies can be found in our Cookie Policy.
                </p>
              </div>

              <div>
                <p className="body-large mb-3 mt-3">
                  <b>Sensitive Information:</b>
                </p>
                <p className="body-large">
                  We advise you to refrain from sending or disclosing any
                  sensitive personal information on or through the Platform
                  unless it is specically requested by us for necessary purposes
                  or required by legal obligations.
                </p>
              </div>
            </div>
          </div>
          <div>
            <h4 className="medium-head mt-3 mb-4">
              2. Usage of Personal Information
            </h4>
            <div className="d-flex flex-column     pb-3">
              <p className="body-large mb-3">
                <b>Platform Operations and Security:</b>
              </p>
              <ul>
                <li>
                  We maintain and operate the Platform effectively to ensure its
                  functionality and security.
                </li>{" "}
                <li>
                  We manage all aspects of transaction processing within our
                  Marketplace, including the creation, authentication, and
                  maintenance of user proles.
                </li>
              </ul>
            </div>

            <div className="d-flex flex-column     pb-3">
              <p className="body-large mb-3">
                <b>User Engagement and Support:</b>
              </p>
              <ul>
                <li>
                  We facilitate your participation in buying, selling, and
                  transferring unique digital assets.
                </li>{" "}
                <li>
                  We provide support via our social media accounts and respond
                  directly to your questions, requests, and feedback to enhance
                  your experience.
                </li>
              </ul>
            </div>

            <div className="d-flex flex-column     pb-3">
              <p className="body-large mb-3">
                <b>Communication, Marketing, and User Experience:</b>
              </p>
              <ul>
                <li>
                  We send critical updates, conrmations, and security alerts
                  regarding important policy changes or administrative matters.
                </li>{" "}
                <li>
                  We engage in direct marketing to inform you of promotions,
                  special offers, and events via various communication channels.
                </li>{" "}
                <li>
                  We personalize your experience on the Platform and optimize
                  your interactions with digital art feeds, continuously
                  improving our services through new features and enhancements.
                </li>
              </ul>
            </div>

            <div className="d-flex flex-column     pb-3">
              <p className="body-large mb-3">
                <b>Data Analysis and Strategic Improvements:</b>
              </p>
              <ul>
                <li>
                  We may analyze user interaction to continuously improve the
                  Platform and develop comprehensive user behavior proles.
                </li>{" "}
                <li>
                  Insights are derived from aggregated and anonymous data to
                  better understand and serve our user base, enhancing service
                  delivery and optimizing our marketing strategies.
                </li>{" "}
              </ul>
            </div>

            <div className="d-flex flex-column     pb-3">
              <p className="body-large mb-3">
                <b>Security and Compliance:</b>
              </p>
              <ul>
                <li>
                  We manage intellectual property disputes and alert users to
                  potential hacks and scams.
                </li>{" "}
                <li>
                  We maintain regular communication with you to ensure you are
                  informed about changes to our services and other relevant
                  updates.
                </li>{" "}
              </ul>
            </div>

            <div className="d-flex flex-column     pb-3">
              <p className="body-large mb-3">
                <b>Storage of Your Personal Information:</b>
              </p>
              <p className="body-large mb-3">
                The length of time that we retain your personal information
                varies depending on the type of data and the reasons for which
                it is collected. We keep your personal information for as long
                as necessary to fulll the purposes outlined in this Privacy
                Policy, or for a period specically required by tax, accounting,
                or other legal requirements
              </p>
              <ul>
                <li>
                  Ongoing Services and Legal Obligations: We retain personal
                  information if we have a legitimate ongoing business need to
                  do so, such as managing our relationship with you,
                  accommodating legal, tax, or accounting requirements, or
                  resolving disputes.
                </li>{" "}
                <li>
                  Completion of Services: Once our services to you are
                  completed, we will either delete or anonymize your personal
                  information, unless there is a need to keep it longer for
                  compliance with legal obligations or for resolving potential
                  disputes.
                </li>{" "}
              </ul>
            </div>
          </div>
          <div>
            <h4 className="medium-head mt-3 mb-4">
              3. Sharing of Personal Information
            </h4>
            <div className="d-flex flex-column     pb-3">
              <p className="body-large mb-3">
                <b>With External Service Providers:</b>
              </p>
              <p className="body-large mb-3">
                Hosting and email services that help maintain the operation of
                our online environment.
              </p>
              <ul>
                <li>
                  Marketing and advertising partners that assist in promotional
                  activities.
                </li>{" "}
                <li>
                  Analytics providers that help us understand user behavior and
                  optimize the Platform.
                </li>
              </ul>
            </div>

            <div className="d-flex flex-column     pb-3">
              <p className="body-large mb-3">
                <b>With Professional Consultants:</b>
              </p>
              <p className="body-large mb-3">
                To ensure our operations adhere to legal and nancial standards,
                we may share necessary personal information with professional
                advisors such as attorneys and accountants.
              </p>
            </div>

            <div className="d-flex flex-column     pb-3">
              <p className="body-large mb-3">
                <b>During Business Changes:</b>
              </p>
              <p className="body-large mb-3">
                In the event of signicant business transactions like mergers,
                acquisitions, or restructurings, personal information may be
                shared with relevant parties to facilitate these changes.
                Additionally, this information might be transferred to
                successors or new aliates during such transitions.
              </p>
            </div>

            <div className="d-flex flex-column     pb-3">
              <p className="body-large mb-3">
                <b>With Regulatory and Government Entities:</b>
              </p>
              <p className="body-large mb-3">
                When required by law, we share information with government
                authorities for compliance and protection, including responding
                to legal processes or meeting regulatory requirements.
              </p>
            </div>

            <div className="d-flex flex-column     pb-3">
              <p className="body-large mb-3">
                <b>With Claimants in Disputes:</b>
              </p>
              <p className="body-large mb-3">
                If disputes arise involving alleged infringement of intellectual
                property rights by digital content creators on our Marketplace,
                we may share relevant creator information with the complainant
                as necessary
              </p>
            </div>

            <div className="d-flex flex-column     pb-3">
              <p className="body-large mb-3">
                <b>Public Blockchain Disclosures:</b>
              </p>
              <p className="body-large mb-3">
                Activities on our Marketplace, such as minting, transactions,
                sales and transfers are recorded and publicly available on the
                blockchain, linked to your unique wallet ID. This public ledger
                is accessible to everyone.
              </p>
            </div>
          </div>
          <div>
            <h4 className="medium-head mt-3 mb-4">
              4. On-chain Data and Metadata
            </h4>
            <div className="d-flex flex-column     pb-3">
              <p className="body-large mb-3">
                When you engage with our Platform, you should be aware that any
                personal information you choose to disclose, including your
                wallet address from the Songbird Network or the Flare Network,
                becomes permanently recorded on these publicly searchable
                blockchains. Once published, it is not within our power to
                modify or delete such data. By using our Platform, you expressly
                consent to this public disclosure, manifesting your intention to
                make such data public.
                <br />
                <br />
                Data stored on the blockchain, such as details of your
                transactions involving NFTs or digital assets, is immutable and
                publicly accessible. These details are linked to your wallet
                address, which is publicly identied with your prole on our
                Marketplace. Should you choose to delete your account, any
                pre-existing transaction history will display your wallet
                address instead of your username on the Marketplace
              </p>

              <div className="d-flex flex-column     pb-3">
                <p className="body-large mb-3">
                  <b>Artist Content on the Platform</b>
                </p>
                <p className="body-large mb-3">
                  As an artist on our Platform, you have the option to embed
                  personal information within the metadata of any NFTs you mint
                  or within the media content referenced by these NFTs. Be
                  advised that once your personal information is embedded in the
                  metadata, it cannot be deleted, and you should have no
                  expectation of privacy for such data. Artists and users
                  involved in the minting, sale, transfer, and usage of NFTs are
                  responsible for ensuring that their personal and sensitive
                  information is adequately protected.
                </p>
              </div>
            </div>
          </div>
          <div>
            <h4 className="medium-head mt-3 mb-4">
              5. Blockchain Data Handling
            </h4>
            <div className="d-flex flex-column     pb-3">
              <p className="body-large mb-3">
                By engaging with "The Catalyst," you acknowledge that any
                personal information you share, including your wallet address on
                the Songbird Network or the Flare Network, is permanently stored
                on these publicly accessible blockchains. The Fat Cats Club, nor
                any other party, has the capability to alter or remove this data
                once it has been recorded on the blockchain. By transacting on
                our Platform, you explicitly consent to the public nature of
                this data.
              </p>

              <div className="d-flex flex-column     pb-3">
                <p className="body-large mb-3">
                  <b>Active and Passive Data Collection</b>
                </p>
                <p className="body-large mb-3">
                  We gather personal information both directly from you and
                  automatically as you navigate through our Platform:
                </p>
                <ul>
                  <li>
                    We send critical updates, conrmations, and security alerts
                    regarding important policy changes or administrative
                    matters.
                  </li>{" "}
                  <li>
                    We engage in direct marketing to inform you of promotions,
                    special offers, and events via various communication
                    channels.
                  </li>{" "}
                  <li>
                    We personalize your experience on the Platform and optimize
                    your interactions with digital art feeds, continuously
                    improving our services through new features and
                    enhancements.
                  </li>
                </ul>
              </div>

              <div className="d-flex flex-column     pb-3">
                <p className="body-large mb-3">
                  <b>External Sources</b>
                </p>
                <p className="body-large mb-3">
                  We also integrate data from external sources to enhance our
                  service offerings:
                </p>
                <ul>
                  <li>
                    Information from social media platforms, articles, and
                    public databases enriches user proles and functionality.
                  </li>{" "}
                  <li>
                    Conversations with our support and curation staff help
                    tailor your experience and address any inquiries or
                    concerns.
                  </li>{" "}
                </ul>
              </div>

              <div className="d-flex flex-column     pb-3">
                <p className="body-large mb-3">
                  <b>Third-Party Contributions</b>
                </p>
                <p className="body-large mb-3">
                  Data may also be sourced from:
                </p>
                <ul>
                  <li>Third-party wallet services and blockchain explorers.</li>{" "}
                  <li>
                    Collaborations with third-party partners who contribute to
                    the enhanced functioning of our services.
                  </li>{" "}
                </ul>
              </div>
            </div>
          </div>
          <div>
            <h4 className="medium-head mt-3 mb-4">6. Usage of Cookies</h4>
            <div className="d-flex flex-column     pb-3">
              <p className="body-large mb-3">
                Our platform utilizes cookies and similar technologies to
                enhance your browsing experience. If you prefer not to use
                cookies, you have the option to adjust your browser settings to
                reject or remove them. By using our platform, you acknowledge
                and agree to our use of these technologies, unless you choose to
                disable them. For detailed information on our specic practices,
                please refer to our Cookie Notice.
              </p>
              <div className="d-flex flex-column     pb-3">
                <p className="body-large mb-3">
                  <b>What Are Cookies?:</b>
                </p>
                <p className="body-large mb-3">
                  Cookies are small text les placed on your device by websites
                  you visit. They are used to store data about your interactions
                  and preferences. Each cookie varies in the length of time it
                  is kept, from those that last only for the duration of your
                  session to persistent cookies that remain on your device for
                  an extended period.
                </p>
              </div>
              <div className="d-flex flex-column     pb-3">
                <p className="body-large mb-3">
                  <b>Functionality and Personalization:</b>
                </p>
                <p className="body-large mb-3">
                  Cookies facilitate smoother browsing by remembering your
                  preferences and login details. They also gather information
                  about how you navigate our site, including your IP address,
                  browser settings, and general usage patterns.
                </p>
              </div>{" "}
              <div className="d-flex flex-column     pb-3">
                <p className="body-large mb-3">
                  <b>Location and Advertising:</b>
                </p>
                <p className="body-large ">
                  We may estimate your general location based on your IP
                  address. Additionally, some cookies help in tracking your
                  activity across our website and other sites to provide
                  targeted advertising tailored to your interests.
                </p>
              </div>
            </div>
          </div>
          <div>
            <h4 className="medium-head mt-3 mb-4">
              7. Legal Basis for Processing Personal Data{" "}
            </h4>
            <div className="d-flex flex-column ">
              <ul>
                <li>
                  <b> Contractual Necessity: </b>
                  We process personal information as essential for executing the
                  services you have contracted us for, including handling
                  transactions, managing your account, and ensuring you can
                  fully engage with our Marketplace.
                </li>

                <li>
                  <b>Legal Obligations: </b>We are committed to complying with
                  legal and regulatory requirements which necessitate the
                  collection and processing of certain personal information.
                </li>

                <li>
                  <b> Legitimate Interests: </b>
                  Our processing activities are often grounded in legitimate
                  business interests, such as enhancing our services, marketing,
                  and protecting our digital environment. We ensure that these
                  interests are balanced against your rights
                </li>

                <li>
                  <b> Consent: </b>
                  At times, we process personal information based on the
                  explicit consent you provide, such as when you agree to
                  receive marketing communications or when setting preferences
                  in your user prole.
                </li>

                <li>
                  <b> Protection of Vital Interests: </b>
                  We may process personal information when it's critical for
                  protecting the safety of individuals in cases where they
                  cannot give consent.
                </li>

                <li>
                  <b> Public Interest:</b>
                  Occasionally, our processing of personal information may be
                  necessary for performing tasks carried out in the public
                  interest or when exercising ocial authority.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h4 className="medium-head mt-3 mb-4">
              8. User Data Rights and Privileges
            </h4>
            <p className="body-large pb-3">
              As a resident of the EU/EEA, you are entitled to specic rights
              concerning your personal information. Our commitment is to ensure
              you can effectively exercise these rights:
            </p>
            <div className="d-flex flex-column ">
              <ul>
                <li>
                  <b> Access and Information: </b>
                  You can request access to the personal information we hold
                  about you and obtain a copy for your records.
                </li>
                <li>
                  <b>Correction: </b> You have the right to have any incorrect
                  or outdated personal information corrected.
                </li>
                <li>
                  <b> Deletion:</b>: If you wish, you can ask for the deletion
                  of your personal information from our records.
                </li>
                <li>
                  <b> Processing Restrictions: </b>
                  You have the option to restrict how we process your personal
                  information.
                </li>
                <li>
                  <b> Objection to Processing:: </b>
                  You can object to the processing of your personal information
                  in certain circumstances.
                </li>
                <li>
                  <b>Data Portability:</b>: You are entitled to obtain a copy of
                  your personal information in a portable format, or we can
                  transfer it directly to another organization if technically
                  feasible.
                </li>
                <li>
                  <b>Automated Decisions: </b>
                  You have the right to not be subject to decisions made solely
                  based on automated processing, including proling, which has
                  legal or other signicant effects on you.
                </li>
                <li>
                  <b>Complaints: </b>
                  You can lodge a complaint with the appropriate data protection
                  authority in your country
                </li>
                <li>
                  <b>Withdrawal of Consent: </b>
                  At any time, you may withdraw any consent you have previously
                  given us to process your personal information.
                </li>
                <li>
                  <b>Non-Discrimination: </b>
                  We assure you that we will not discriminate against you for
                  exercising any of your privacy rights.
                </li>
              </ul>
              <p className="body-large mt-3">
                These rights ensure that you maintain control over your personal
                information while interacting with our services.
              </p>
            </div>
          </div>
          <div>
            <h4 className="medium-head mt-3 mb-4">
              9. Privacy Rights for California Residents
            </h4>
            <p className="body-large pb-3">
              Under the California Consumer Privacy Act (CCPA), residents of
              California are afforded specic rights regarding their personal
              information. Here’s an overview of these rights:
            </p>
            <div className="d-flex flex-column ">
              <ul>
                <li>
                  <b> Right to Know and Access Information: </b>
                  We outline the categories of personal information we collect
                  in the section “Collection of Personal Information.”
                  California residents have the right to make veriable requests
                  to access details about the personal information we collect,
                  the sources from which it is collected, the purposes for
                  collection, and the specic pieces of personal information we
                  have about them.
                </li>
                <li>
                  <b>Right to Deletion:</b> You may request the deletion of your
                  personal information from our records.
                </li>
                <li>
                  <b> Right to Data Portability:</b>: You can request a copy of
                  your personal information in a format that allows for the easy
                  transfer to another entity
                </li>
                <li>
                  <b> Right to Non-Discrimination: </b>
                  We will not discriminate against you for exercising any of
                  your CCPA rights.
                </li>
              </ul>
              <p className="body-large mt-3 mb-3">
                <b>Additional Provisions:</b>
              </p>

              <ul>
                <li>
                  <b>No Sale of Personal Data: </b>
                  We arm that we do not sell any personal data for monetary or
                  other valuable consideration.
                </li>{" "}
                <li>
                  <b>Identity Verication Requirement: W </b>
                  en you make a request regarding your rights, we may ask for
                  additional information to verify your identity. To exercise
                  your rights, you can contact us via Twitter at{" "}
                  <a href="https://x.com/thefatcatsNFT">
                    https://x.com/thefatcatsNFT.
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h4 className="medium-head mt-3 mb-4">10. Children</h4>
            <p className="body-large pb-3">
              Our Platform is not designed for children under the age of 16. We
              do not intentionally gather personal information from children
              within this age group. Should it come to our attention that we
              have inadvertently collected personal information from a child
              under 16 without the necessary consent from their parent or
              guardian, as mandated by law, we will take immediate steps to
              remove that information from our systems.
            </p>
          </div>
          <div>
            <h4 className="medium-head mt-3 mb-4">11. External Links</h4>

            <div>
              <p className="body-large pb-3">
                <b>Third-Party Websites:</b>
              </p>
              <p className="body-large pb-3">
                Our Platform is not designed for children under the age of 16.
                We do not intentionally gather personal information from
                children within this age group. Should it come to our attention
                that we have inadvertently collected personal information from a
                child under 16 without the necessary consent from their parent
                or guardian, as mandated by law, we will take immediate steps to
                remove that information from our systems.
              </p>
            </div>

            <div>
              <p className="body-large pb-3">
                <b>Privacy Policy and Terms:</b>
              </p>
              <p className="body-large pb-3">
                When you interact with these external sites, any information you
                provide is governed by their specic privacy policies and terms
                of service, not ours.
              </p>
            </div>

            <div>
              <p className="body-large pb-3">
                <b>Recommendation:</b>
              </p>
              <p className="body-large pb-3">
                We recommend that you consult the privacy statements and terms
                of the Third-Party Sites you visit. We provide these links as a
                convenience and do not endorse, nor have we reviewed, the
                external content.
              </p>
            </div>
          </div>
          <div>
            <h4 className="medium-head mt-3 mb-4">12. Security</h4>
            <p className="body-large pb-3">
              We are committed to safeguarding your personal information.
              Despite our best efforts, no security measures are failsafe; thus,
              we cannot guarantee the absolute security of your data.
              Transmission of personal information is at your own risk, and we
              encourage you to be vigilant in protecting your personal
              information online.
            </p>
          </div>{" "}
          <div>
            <h4 className="medium-head mt-3 mb-4">13. Contact Us</h4>
            <p className="body-large pb-3">
              Should you have any inquiries regarding our Privacy Policy or the
              management of your personal information, please do not hesitate to
              reach out to us on Twitter{" "}
              <a href="https://x.com/thefatcatsNFT">
                [https://x.com/thefatcatsNFT]
              </a>
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Privacy;
