import React, { useEffect, useState } from "react";
import "../style/main.scss";
import img5 from "../assets/icon/circleSuccess.svg";
import star from "../assets/icon/spiked-circle/black/45px.svg";
import { getAllUsers, getNewArtists, getUserData } from "../firebase/firebase";
import verified from "../assets/icon/verified-artist.svg";
import { useNavigate } from "react-router-dom";
import placeholder from "../assets/images/profile-1.svg";
import thumb from "../assets/icon/profile-picture.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Popover, Skeleton } from "antd";
import ArtistPopUp from "./shared/artistpopup";
import { useAccount } from "wagmi";
const NewArtists = () => {
  const navigate = useNavigate();
  const [newArtist, setNewArtist] = useState([]);
  const [userData, setUserData] = useState([]);
  const { address } = useAccount();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    getAllUsers().then(async (data) => {
      console.log(data);
      // Filter out artists that are not approved
      const approvedArtists = data?.filter((artist) => artist?.isWhiteListed);
      console.log(approvedArtists, "approved artist list");

      // Array to hold artists with appended userData
      const artistsWithUserData = [];
      if (approvedArtists) {
        for (let artist of approvedArtists) {
          console.log(artist?.id, "address of artist");

          // Fetch userData for the artist's address
          let userData = await getUserData(artist?.id);
          console.log(userData, "user Data");

          // Append userData fields to the artist object
          const artistWithUserData = { ...artist, ...userData };
          console.log(artistWithUserData, "Artist with user data");

          // Push the updated artist to the array
          artistsWithUserData.push(artistWithUserData);
        }
      }
      console.log("artist", artistsWithUserData);
      // Set state with the array containing artists with appended userData
      setNewArtist(artistsWithUserData);
      setLoading(false);
    });
  }, []);

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const getArtistImage = (accountAddress) => {
    let artistImage = thumb;
    userData?.forEach((user) => {
      if (user?.id === accountAddress) {
        if (user?.image) {
          artistImage = user?.image;
        } else {
          artistImage = thumb;
        }
      }
    });

    return artistImage;
  };

  const getArtistNamebyAdress = (accountAddress) => {
    let artistName = "";
    if (address === accountAddress) {
      return "You";
    } else {
      userData?.forEach((user) => {
        if (user?.id === accountAddress) {
          artistName = user?.userName;
        }
      });
    }

    return artistName;
  };

  return (
    <>
      {newArtist?.length>0&&<section className="trending-artist site-container">
        {/* <SectionHeader title="new" title2="artists" isImage={true} img={img5} /> */}
        <div>
          <div className="section-header">
            <div className="head-img">
              <img src={star} alt="star" className="img-45" />
            </div>
            <h5 className="section-head">
              <img src={img5} alt="circle" />
              <span>new</span>
              artists
            </h5>
            <div className="view-more"></div>
          </div>
        </div>
        <div
          className="trending-artist-content   m-auto"
          style={{ maxWidth: "800px" }}
        >
          {loading ? (
            <div className="d-flex gap-3">
              <div className="d-flex flex-column">
                <Skeleton.Avatar
                  active
                  size="large"
                  shape="circle"
                  style={{ width: "130px", height: "130px" }}
                />
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3"
                  style={{
                    width: "130px",
                    height: "16px",
                    borderRadius: "4px",
                  }}
                />
              </div>

              <div className="d-flex flex-column hide-on-mobile">
                <Skeleton.Avatar
                  active
                  shape="circle"
                  style={{ width: "130px", height: "130px" }}
                />
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3"
                  style={{
                    width: "130px",
                    height: "16px",
                    borderRadius: "4px",
                  }}
                />
              </div>

              <div className="d-flex flex-column hide-on-mobile">
                <Skeleton.Avatar
                  active
                  size="large"
                  shape="circle"
                  style={{ width: "130px", height: "130px" }}
                />
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3"
                  style={{
                    width: "130px",
                    height: "16px",
                    borderRadius: "4px",
                  }}
                />
              </div>

              <div className="d-flex flex-column hide-on-mobile">
                <Skeleton.Avatar
                  active
                  size="large"
                  shape="circle"
                  style={{ width: "130px", height: "130px" }}
                />
                <Skeleton.Avatar
                  active
                  round
                  size="large"
                  shape="button"
                  className="mt-3"
                  style={{
                    width: "130px",
                    height: "16px",
                    borderRadius: "4px",
                  }}
                />
              </div>
            </div>
          ) : (
            <>
              {newArtist?.slice(0, 4).map((item, index) => {
                return (
                  <div
                    key={index}
                    className="t-artist-card d-flex flex-column align-items-center pointer"
                  >
                    <div
                      className="t-artist-card-head"
                      onClick={() => navigate(`/profile/${item?.id}`)}
                    >
                      {item?.image ? (
                        <img
                          src={item?.image}
                          alt="card img"
                          className="img-100"
                        />
                      ) : (
                        <img
                          src={placeholder}
                          alt="card img"
                          className="img-100"
                        />
                      )}
                    </div>
                    <p className="body-large  ">
                      <Popover
                        placement="top"
                        trigger="hover"
                        content={
                          <>
                            <ArtistPopUp
                              userProfile={item?.image}
                              verified={verified}
                              artistName={item?.userName}
                            />
                          </>
                        }
                      >
                        <span
                          className="hover-underline"
                          onClick={() => navigate(`/profile/${item?.id}`)}
                        >
                          {item?.userName.length > 14 ? (
                            <>@{item?.userName.substring(0, 14)}...</>
                          ) : (
                            <>@{item?.userName.substring(0, 14)}</>
                          )}
                        </span>
                      </Popover>

                      <span>new</span>
                    </p>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </section>}
    </>
  );
};

export default NewArtists;
