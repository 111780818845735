import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { toast } from "react-toastify";
import { useAccount, useWalletClient } from "wagmi";
import { useSwitchNetwork, useNetwork } from "wagmi";
import Header from "../header";
import InputBox from "../shared/inputBox";
import Textarea from "../shared/textarea";
import Button from "../button";
import loading from "../../assets/icon/loader-small-white.svg";
import { NFTContext } from "../context/appContext";
import {
  getAllArtistDetails,
  getAllUsers,
  getCollections,
  saveCollection,
  saveCollectionStats,
  getUserData,
} from "../../firebase/firebase";
import CreateContract from "../../contractInteraction/contractcreation";

const CreateCollection = () => {
  const [createFinish, setCreateFinish] = useState(false);
  const [collectionName, setCollectionName] = useState("");
  const [symbolName, setSymbolName] = useState("");
  const [description, setDescription] = useState("");
  const [userNameError, setUserNameError] = useState(false);
  const [registerArtistError, setRegisterArtistError] = useState(false);
  const [profileApproved, setProfileApproved] = useState(false);
  const [isloading, setLoading] = useState(false);
  const [collectionModel, setCollectionModel] = useState("");
  const [docId, setDocId] = useState(null);
  const [nameError, setNameError] = useState(false);
  const [symbolError, setSymbolError] = useState(false);
  const [descError, setDescError] = useState(false);
  const [symbolValidationError, setSymbolValidationError] = useState(false);

  const { updateModal } = useContext(NFTContext);

  const { address } = useAccount();
  const { data: signer } = useWalletClient();
  const { chains, pendingChainId, switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();
  const [selectedNetwork, setSelectedNetwork] = useState("Flrare Network");
  const [switchChain, setSwitchChain] = useState(null);

  const [selectedChainName, setSelectedChainName] = useState("Flare Network");
  const [selectedChain, setSelectedChain] = useState(null);

  useEffect(() => {
    console.log(selectedNetwork, "selected network");
    if (selectedChainName !== selectedNetwork) {
      console.log("different network");
      setSwitchChain(true);
    } else {
      setSwitchChain(false);
    }
  }, [selectedChain, selectedNetwork]);

  const navigate = useNavigate();

  useEffect(() => {
    console.log("address", address);

    const getAllArtists = async () => {
      const artists = await getUserData(address);
      if (artists?.isWhiteListed) {
        setProfileApproved(true);
      } else {
        setProfileApproved(false);
      }
    };

    const getUsers = async () => {
      const users = await getAllUsers();
      if (users?.length > 0) {
        const user = users?.find((user) => user?.id === address);
        if (user?.userName === "" || user?.userName === undefined) {
          setUserNameError(true);
        }
      }
    };
    getUsers();
    getAllArtists();
  }, [address]);

  const CreateCollectionStats = async () => {
    const collectionId = await getLastCollectionId();
    console.log("collectionId", collectionId);
    const data = {
      collectionId: collectionId,
      artworkCount: 0,
      createdAt: new Date().getTime(),
      creatorEarning: 0,
      volume: [],
      SGBvolume: [],
      FLRvolume: [],
      USDvolume: [],
      floorPrice: [],
      listedCount: 0,
      saleCount: 0,
      owners: [],
    };
    saveCollectionStats(data);
  };

  const handleCreateFinish = async () => {
    setLoading(true);
    const user = JSON.parse(localStorage?.getItem("CatalystUserData"));
    let hasError = false;

    if (address == undefined || address == null || address == "") {
      toast.error("Please connect wallet");
      hasError = true;
    } else {
      if (collectionName == "") {
        setNameError(true);
        hasError = true;
      } else {
        setNameError(false);
      }

      if (symbolName == "") {
        setSymbolError(true);
        hasError = true;
      } else if (symbolValidationError) {
        setSymbolError(true);
        hasError = true;
      } else {
        setSymbolError(false);
      }

      if (description == "") {
        setDescError(true);
        hasError = true;
      } else {
        setDescError(false);
      }

      if (hasError) {
        setLoading(false);
        // toast.error("Please fill all the fields", {
        //   toastId: "applyError",
        // });
        return;
      }

      setCreateFinish(true);
      setCollectionModel("modal1");

      try {
        const res = await CreateContract(
          signer,
          collectionName,
          symbolName,
          selectedNetwork
        );

        console.log("create Contract response", res.hash);
        setCollectionModel("modal2");

        if (res.success) {
          const resp = await saveCollection(
            collectionName,
            symbolName,
            description,
            address,
            selectedNetwork,
            res.contractAddress,
            res?.hash
          );
          // setCollectionModel("modal3");

          setCollectionName("");
          setSymbolName("");
          setDescription("");
          setLoading(false);
          console.log("save collection response", resp.docId);
          setDocId(resp.docId);
          CreateCollectionStats();
          updateModal(true);
          navigate(`/explore-collections/${resp?.docId}`);
        }
      } catch (err) {
        console.error(err);
        setLoading(false);
        setCollectionModel("error");
      }
    }
  };

  const getLastCollectionId = async () => {
    const collectionId = await getCollections();

    if (collectionId?.length > 0) {
      const collectionSorted = collectionId.sort((a, b) => {
        const timeA = a?.data?.timestamp?.seconds;
        const timeB = b?.data?.timestamp?.seconds;
        return timeB - timeA;
      });
      const lastCollectionId = collectionSorted[0].documentId;
      return lastCollectionId;
    }
  };

  useEffect(() => {
    console.log(selectedNetwork, "selecteed networks");
  }, [selectedNetwork]);

  useEffect(() => {
    console.log(chain?.name, "naemmmmmmmmmmmmmmm");
    setSelectedNetwork(chain?.name);
  }, [chain]);

  const handleSwtichNetwork = (chain) => {
    switchNetwork?.(chain.id);
    setSelectedNetwork(chain?.name);
  };

  const hideFinishModel = () => {
    setCreateFinish(false);
  };

  const handleSymbolChange = (e) => {
    setSymbolError(false);
    const value = e.target.value.toUpperCase(); // Convert value to uppercase
    const isValid = /^[A-Z0-9]*$/.test(value); // Validate only uppercase alphanumeric characters
    console.log(isValid, "is Valid");
    setSymbolName(value);
    setSymbolValidationError(!isValid);
  };
  
  useEffect(() => {
    console.log(selectedChain, "selected chain");
  }, [selectedChain]);

  return (
    <div>
      <div className="create-collections create-artwork">
        <Header head="Create" />
        <div className="create-artwork-content">
          <h4 className="medium-head">Create New Collection</h4>
          <p className="mt-3 mb-5">
            Deploy a standard NFT that you can mint to at any time. The details
            you fill in below will be added to the blockchain and you will not
            be able to change them.
          </p>
          {/* collection-name */}
          <div className="art-name mt-40 d-flex flex-column">
            <InputBox
              type="text"
              placeholder="My Collection Name.."
              label="Collection Name *"
              optional="Required"
              onChange={(e) => {
                setCollectionName(e.target.value);
                setNameError(false);
              }}
              value={collectionName}
              maxLength={30}
              warningMessage={nameError && "Name is missing"}
            />
          </div>

          {/* symbol name */}
          <div className="art-name mt-40 d-flex flex-column">
            <InputBox
              type="text"
              label="Collection Symbol *"
              optional="Required"
              className="text-uppercase"
              placeholder="MCN"
              onChange={handleSymbolChange}
              value={symbolName}
              maxLength={8}
              warningMessage={
                symbolError
                  ? symbolValidationError
                    ? "Collection symbols can't contain special characters or spaces"
                    : "Symbol is missing"
                  : ""
              }
            />
          </div>

          {/* Collection Description */}
          <div className="art-name mt-30 d-flex flex-column ">
            <div className="input-label v-center justify-content-between">
              <label className="text-black medium v-center justify-content-between w-100">
                Description *
                <span className="body-medium text-capitalize fw-500 text-medium-grey ms-2">
                  Required
                </span>
              </label>
            </div>
            <Textarea
              maxLength={500}
              placeholder="About Collection"
              onChange={(e) => {
                setDescription(e.target.value);
                setDescError(false);
              }}
              value={description}
              warningMessage={descError ? "Description is missing" : ""}
            />
            {/* {descError && (
              <p
                className="warning"
                style={{
                  zIndex: 1,
                  marginTop: "-1.5rem",
                  color: "red",
                }}
              >
                Description is missing
              </p>
            )} */}
          </div>

          {/* select Network */}
          <div className="art-name mt-40 d-flex flex-column">
            <label className="text-black medium v-center justify-content-between">
              Select Network *
              <span className="body-medium no-text-transform fw-500 text-medium-grey ms-2">
                Required
              </span>
            </label>
            <Dropdown className="select-collection">
              <Dropdown.Toggle id="dropdown-basic">
                {selectedChainName}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                {chains.map((chain) => (
                  <Dropdown.Item
                    key={chain.chainId}
                    value={chain.chainId}
                    selected={chain.chainId === pendingChainId}
                    onClick={() => {
                      const chainDisplayName =
                        chain.name === "Flare Mainnet"
                          ? "Flare Network"
                          : chain.name === "Songbird Mainnet"
                            ? "Songbird Network"
                            : chain.name;
                      setSelectedChainName(chainDisplayName);
                      setSelectedChain(chain);
                    }}
                  >
                    {chain.name === "Flare Mainnet"
                      ? "Flare Network"
                      : chain.name === "Songbird Mainnet"
                        ? "Songbird Network"
                        : chain.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          {userNameError ? (
            <Button
              text="Complete your profile first"
              className="btn-prime btn-primary mt-30 br-30 font-18"
              height="50px"
              width="100%"
              onClick={() => navigate(`/profile/${address}`)} // Navigate to the profile page
            />
          ) : !profileApproved ? (
            <Button
              text="Apply For Artist"
              className="btn-prime btn-primary mt-60 br-30 font-18"
              height="50px"
              width="100%"
              onClick={() => {
                window.open(
                  "https://discord.gg/zAQ9RAh7db",
                  "_blank"
                );
              }}
            />
          ) : switchChain ? (
            <Button
              text="Switch network"
              className="btn-prime btn-primary mt-60 br-30 font-18"
              height="50px"
              width="100%"
              onClick={() => handleSwtichNetwork(selectedChain)}
              loading={isloading}
            />
          ) : (
            <Button
              text="Create"
              className="btn-prime btn-primary mt-60 br-30 font-18"
              height="50px"
              width="100%"
              onClick={handleCreateFinish} // Handle the creation process
              loading={isloading}
            />
          )}
        </div>
        <Modal
          show={createFinish}
          onHide={hideFinishModel}
          className="sign-modal create-collection-modal"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <label htmlFor="" className="medium">
                Confirm
              </label>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="create-collection-modal">
              <div className="pb-5 v-center flex-column top-content">
                {collectionModel === "modal1" && (
                  <>
                    <p className="body-medium text-center mt-5 pt-1">
                      Confirm this transaction in your wallet.
                    </p>
                    <label className="mt-30 pt-1 no-text-transform text-black text-center w-100">
                      Waiting for blockchain confirmation…
                    </label>
                  </>
                )}
                {collectionModel === "modal2" && (
                  <>
                    <p className="body-medium text-center mt-5 pt-1">
                      Please wait
                    </p>
                    <label className="mt-30 pt-1 no-text-transform text-black text-center">
                      Your smart contract is being created...
                    </label>
                  </>
                )}
                {collectionModel === "modal3" && (
                  <>
                    <p className="body-medium text-center mt-5 pt-1 text-center">
                      Your smart contract has been deployed to the{" "}
                      {selectedNetwork === "Coston2" ? "Flare" : "Songbird"}{" "}
                      Network!
                    </p>
                    <label className="mt-30 pt-1 no-text-transform text-black text-center w-100">
                      Congratulations!
                    </label>
                  </>
                )}
                {collectionModel === "error" && (
                  <>
                    <p className="body-medium text-center mt-5 pt-1">
                      Sorry, we couldn't create this smart contract for you.
                    </p>
                    <label className="mt-30 pt-1 no-text-transform text-black text-center">
                      Please try again...
                    </label>
                  </>
                )}
              </div>
              <div className="h-center">
                <Button
                  className="btn-prime btn-primary mt-5 d-none"
                  width="138px"
                  height="36px"
                  onClick={handleCreateFinish}
                  imageSrc={loading}
                  imageClassName="img-18"
                />
              </div>

              <div className="h-center">
                {collectionModel === "modal1" && (
                  <Button
                    text=""
                    className="btn-prime btn-primary mt-50"
                    width="138px"
                    height="36px"
                    imageSrc={loading}
                    imageClassName="rotate-360"
                  />
                )}
              </div>

              <div className="h-center">
                {collectionModel === "modal2" && (
                  <Button
                    text=""
                    className="btn-prime btn-primary mt-50"
                    width="138px"
                    height="36px"
                    imageSrc={loading}
                    imageClassName="rotate-360"
                  />
                )}
              </div>

              <div className="h-center">
                {collectionModel === "error" && (
                  <Button
                    text="Try Again"
                    className="btn-prime btn-primary mt-50"
                    width="138px"
                    height="36px"
                    onClick={handleCreateFinish}
                    imageClassName="rotate-360"
                  />
                )}
              </div>

              {/* if success btns */}
              {collectionModel === "modal3" && (
                <div className="on-success mt-5 v-center gap-4 h-center">
                  <Button
                    text="View Collection"
                    className="btn-prime btn-secondary "
                    width="175px"
                    height="36px"
                    onClick={() => navigate(`/explore-collections/${docId}`)}
                  />
                  <Button
                    text="Create new Artwork"
                    className="btn-prime btn-primary "
                    width="175px"
                    height="36px"
                    onClick={() => navigate("/create")}
                  />
                </div>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default CreateCollection;
