import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import { Tab, Tabs } from "react-bootstrap";
// icons
import star20 from "../../assets/icon/spiked-circle/black/24px.svg";
import grid from "../../assets/icon/display-grid.svg";
import artwork from "../../assets/icon/display-artwork.svg";
import ExploreArt from "./exploreArt";
import useScrollToTop from "../../customHooks/scrollToTop";
import AllCollections from "../allcollections";
import { useLocation } from "react-router-dom";
import { ClearAllProvider } from "./ClearAllContext";
import { TagProvider } from "./TagContext";
import { useTagContext } from "./TagContext";
import Footer from "../footer";


const Explore = () => {
  const location = useLocation();

  const { art } = location.state || {};
  const [fromSearch, setFromSearch] = useState(false);

  useEffect(() => {
    console.log(art, "art data........");
    if (art?.length > 0) {
      setFromSearch(true);
    }
  }, [art]);

  const { tagName } = useTagContext();
  console.log(tagName);

  // state data from url
  useEffect(() => {
    if (location.state) {
      setKey(location?.state?.tab);
    }
  }, [location]);

  const [key, setKey] = useState("artwork");
  useScrollToTop();
  return (
    <>
      <div className="explore-art  explore-art-wrapper ">
        <div className="explore-art-content">
          <h4 className="fw-bold ">Search</h4>
          <p className="body-large ">The Catalyst gallery of artwork.</p>
          <div className="profile-tabs">
            <Tabs
              defaultActiveKey="artwork"
              id="uncontrolled-tab-example"
              className="mb-3 profile-tabs"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab
                eventKey="artwork"
                title={
                  <span>
                    <img
                      src={key == "artwork" ? star20 : artwork}
                      alt="star"
                      className={
                        key == "artwork"
                          ? "hide-on-mobile spikeimg"
                          : "hide-on-mobile"
                      }
                    />
                    artwork
                  </span>
                }
              >
                <ClearAllProvider>
                  <ExploreArt
                    fromSearch={fromSearch}
                    art={art}
                    tagName={tagName}
                  />
                </ClearAllProvider>
              </Tab>

              {/* collections */}

              <Tab
                eventKey="collections"
                title={
                  <span className="pointer">
                    <img
                      src={key == "collections" ? star20 : grid}
                      alt="star"
                      className={
                        key == "collections"
                          ? "hide-on-mobile spikeimg"
                          : "hide-on-mobile"
                      }
                    />
                    collections
                  </span>
                }
              >
                <ClearAllProvider>
                <AllCollections />

                </ClearAllProvider>
              </Tab>
            </Tabs>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Explore;
