import React, { useState, useEffect,useContext } from "react";
import "../../style/main.scss";
import { Modal } from "react-bootstrap";
import user from "../../assets/images/face-4.png";
import Button from "../button";
import {
  unfollowArtist,
  checkIfFollowed,
  handleFollow,
  getImageByUsername,
  getAllUsers,
  getUserDataByUserName,
} from "../../firebase/firebase";
import placeholder from "../../assets/images/profile-1.svg";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { NFTContext } from "../context/appContext";
const FollowModal = ({
  userName,
  id,
  show,
  handleModal1,
  data,
  dataType,
  setShowFollowModal,
}) => {
  const { address } = useAccount();
  const [isFollowed, setIsFollowed] = useState(Array(data?.length).fill(false));
  const [images, setImages] = useState({});
  const [userAddresses, setUserAddresses] = useState({});
  let username = localStorage?.getItem("userName");
  const navigate = useNavigate({ forceReload: true });

  const [userData, setUserData] = useState([]);
  const { reloadProfile, reload } = useContext(NFTContext);


  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      console.log("users", users);
      setUserData(users);
    };
    fetchUserData();
  }, []);
  const getNamebyAddress = (accountAddress) => {
    console.log(accountAddress);
    if (address === accountAddress) {
      return "You";
    }

    const user = userData?.find((user) => user?.id === accountAddress);
    const artistName = user ? user?.userName : accountAddress;

    console.log(accountAddress, artistName, "account address");
    return "@" + artistName;
  };
  useEffect(() => {
    if (dataType === "following") {
      if (id) {
        data.forEach(async (item, index) => {
          const isFollowedBack = await checkIfFollowed(address, item);
          console.log(isFollowedBack, "isFollowedBack");
          setIsFollowed((prevIsFollowed) => {
            const updatedIsFollowed = [...prevIsFollowed];
            updatedIsFollowed[index] = !isFollowedBack;
            return updatedIsFollowed;
          });
        });
      } else {
        setIsFollowed(Array(data.length).fill(false));
      }
    } else if (dataType === "followers") {
      data.forEach(async (item, index) => {
        const isFollowedBack = await checkIfFollowed(address, item);
        console.log(isFollowedBack, "isFollowedBack");
        setIsFollowed((prevIsFollowed) => {
          const updatedIsFollowed = [...prevIsFollowed];
          updatedIsFollowed[index] = !isFollowedBack;
          return updatedIsFollowed;
        });
      });
    }
  }, [dataType, data, username]);

  useEffect(() => {
    const fetchData = async () => {
      await getImagesAndAddresses();
    };
    fetchData();
  }, [data]);

  const getImagesAndAddresses = async () => {
    console.log(data, "data of username");
    setImages([null]);
    try {
      for (let index = 0; index < data.length; index++) {
        const item = data[index];
        const image = await getImageByUsername(item);
        const userData = await getUserDataByUserName(item);
        console.log(`Image fetched successfully for ${item}:`, image);
        console.log(`User data fetched successfully for ${item}:`, userData);
        setImages((prevImages) => ({
          ...prevImages,
          [index]: image,
        }));
        setUserAddresses((prevUserAddresses) => ({
          ...prevUserAddresses,
          [index]: userData?.documentId,
        }));
      }
    } catch (error) {
      console.error("Error fetching images or user data:", error);
      // Handle error if needed
    }
  };

  const followhandle = async (index, item) => {

    
    if (isFollowed[index] === false) {


      await unfollowArtist(address, item);
    } else {
      await handleFollow(address, item);
    }
    setIsFollowed((prevIsFollowed) => {
      const newIsFollowed = [...prevIsFollowed];
      newIsFollowed[index] = !prevIsFollowed[index];
      return newIsFollowed;
    });
    reload();
  };

  const handleUserClick = (item) => {
    if (item) {
      setShowFollowModal(false); // Close the modal
      navigate(`/profile/${item}`);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleModal1}
        centered
        size="lg"
        className="follower-modal"
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            <label className="medium">
              {dataType === "followers"
                ? `Followers (${data?.length})`
                : `Following (${data?.length})`}
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data?.map((item, index) => (
            <div
              key={index}
              className="d-flex align-items-center justify-content-between mb-2"
            >
              <div className="follower pointer">
                <div
                  className="follower-img"
                  onClick={() => handleUserClick(item)}
                >
                  {images[index] ? (
                    <img
                      src={images[index]}
                      className="img-100 rounded-circle"
                    />
                  ) : (
                    <img src={placeholder} className="img-100 rounded-circle" />
                  )}
                </div>
                <label
                  className="text-black text-lowercase pointer"
                  onClick={() => handleUserClick(item)}
                >
                  {getNamebyAddress(item) == "You" ? (
                    <span className="text-capitalize">You</span>
                  ) : getNamebyAddress(item).length > 14 ? (
                    getNamebyAddress(item).substring(0, 14) + "..."
                  ) : (
                    getNamebyAddress(item)
                  )}
                </label>
              </div>
              <div className="follow-btn" onClick={(e) => e.stopPropagation()}>
                {item !== address && (
                  <Button
                    width="126px"
                    height="36px"
                    text={!isFollowed[index] ? "Following" : "Follow"}
                    className={
                      isFollowed[index]
                        ? "btn-prime btn-primary"
                        : "btn-prime btn-secondary bg-transparent"
                    }
                    onClick={() => followhandle(index, item)}
                  />
                )}
              </div>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FollowModal;
