import React from "react";
import backArrow from "../assets/icon/arrow-left.svg";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const navigate = useNavigate();

  const handleBackNavigation = () => {
    if (props?.location) {
      navigate(props.location);
    } else {
      navigate(-1);
    }
  };

  return (
    <div>
      <div className={`header2 ${props.className}`}>
        {/* To navigate based on condition */}
        <img
          src={backArrow}
          alt="backArrow"
          className="pointer"
          onClick={handleBackNavigation}
        />
        <h6 className="fw-normal w-100 text-center">{props.head}</h6>
      </div>
    </div>
  );
};

export default Header;
